import s from './footer.module.scss';
import { ReactComponent as Im } from '../../../images/logo/im.svg';

export const Footer = () => {
	return (
		<footer>
			<div>
				<div>
					<p>Политика кофиденциальности</p>
					<p>Все права защищены</p>
				</div>
			</div>

			<Im />

			{/* <svg
				width='106'
				height='72'
				viewBox='0 0 106 72'
				xmlns='http://www.w3.org/2000/svg'>
				<g id='Group 1'>
					<path
						id='Star 1'
						d='M14.2134 2.85187C15.0222 1.54212 16.9268 1.54212 17.7356 2.85187C18.3113 3.78403 19.5136 4.10618 20.4782 3.58673C21.8335 2.85687 23.483 3.80918 23.5286 5.34787C23.561 6.44296 24.4411 7.32311 25.5362 7.35555C27.0749 7.40113 28.0272 9.05059 27.2974 10.4059C26.7779 11.3705 27.1001 12.5728 28.0322 13.1485C29.342 13.9573 29.342 15.8619 28.0322 16.6707C27.1001 17.2464 26.7779 18.4487 27.2974 19.4133C28.0272 20.7686 27.0749 22.4181 25.5362 22.4636C24.4411 22.4961 23.561 23.3762 23.5286 24.4713C23.483 26.01 21.8335 26.9623 20.4782 26.2325C19.5136 25.713 18.3113 26.0352 17.7356 26.9673C16.9268 28.2771 15.0222 28.2771 14.2134 26.9673C13.6377 26.0352 12.4354 25.713 11.4708 26.2325C10.1155 26.9623 8.46604 26.01 8.42046 24.4713C8.38802 23.3762 7.50787 22.4961 6.41278 22.4636C4.87409 22.4181 3.92178 20.7686 4.65165 19.4133C5.1711 18.4487 4.84894 17.2464 3.91678 16.6707C2.60703 15.8619 2.60703 13.9573 3.91678 13.1485C4.84894 12.5728 5.1711 11.3705 4.65165 10.4059C3.92178 9.05059 4.87409 7.40113 6.41278 7.35555C7.50787 7.32311 8.38802 6.44296 8.42046 5.34787C8.46604 3.80918 10.1155 2.85687 11.4708 3.58673C12.4354 4.10618 13.6377 3.78403 14.2134 2.85187Z'
						fill='black'
					/>
					<path
						id='Vector'
						fill-rule='evenodd'
						clip-rule='evenodd'
						d='M40.7351 28.4092C37.1904 28.7856 33.7867 29.9017 31.0758 31.5775L29.2867 32.6829V31.0423V29.4012L17.4389 29.406C10.9223 29.4081 4.33337 29.5615 2.79555 29.7462C0.0441964 30.0769 0 30.1105 0 31.8714C0 33.4316 0.173058 33.6611 1.34825 33.6611C3.40791 33.6611 6.08258 34.9662 7.36534 36.5972C8.4809 38.0152 8.51977 38.5211 8.51977 51.6064C8.51977 59.4211 8.29932 65.5596 7.99846 66.1219C7.39995 67.2401 4.80036 68.2726 2.58309 68.2726C1.20288 68.2726 1.06497 68.442 1.06497 70.1363V72H18.1045H35.144V70.1954C35.144 68.5495 34.9779 68.36 33.2505 68.0362C32.2095 67.8408 30.8916 67.2151 30.3218 66.6454C29.3549 65.6784 29.2867 64.7795 29.2867 52.9807V40.3512L30.751 39.0658C33.005 37.0876 36.5594 35.6664 38.2985 36.0482C40.2133 36.4689 41.9465 38.3091 42.6116 40.6276C43.4253 43.4641 43.2852 65.7284 42.4471 66.8749C42.069 67.3924 40.7591 67.9457 39.5184 68.1118C37.3847 68.3978 37.274 68.5011 37.274 70.2066V72H53.5148H69.7556V70.1954C69.7556 68.5495 69.5895 68.36 67.8621 68.0362C66.8211 67.8408 65.5032 67.2151 64.9334 66.6454C63.9664 65.6784 63.8983 64.779 63.8983 52.9488C63.8983 38.6308 63.5756 39.7277 68.566 37.0743C71.41 35.562 71.9521 35.52 74.2929 36.6307C77.3861 38.0983 77.7429 39.9071 77.7429 54.1075C77.7429 67.9696 77.8366 67.6145 74.0362 68.1246C72.0064 68.3967 71.8855 68.5138 71.8855 70.2066V72H88.9251H105.965V70.197C105.965 68.4606 105.873 68.3797 103.501 68.024C101.989 67.7971 100.551 67.1672 99.7739 66.3903C98.5151 65.1315 98.5098 65.074 98.5098 52.6085C98.5098 38.4311 97.9917 35.8411 94.4475 32.2968C91.9065 29.7558 89.5108 28.8953 83.6684 28.4251C75.2407 27.7462 70.196 28.7233 65.0921 32.0221L61.8524 34.1158L59.6788 32.177C58.4834 31.1105 56.4877 29.8969 55.2438 29.4805C52.3774 28.5205 44.8938 27.9677 40.7351 28.4092Z'
						fill='black'
					/>
					<g id='Vector_2'>
						<path
							d='M33.3032 8.49551H36.4535V9.39668H33.3032V8.49551ZM33.4024 11.5923H32.229V5.85754H36.8416V6.75052H33.4024V11.5923Z'
							fill='black'
						/>
						<path
							d='M39.776 11.6496C39.3667 11.6496 39.0057 11.5813 38.6927 11.4448C38.3798 11.3028 38.1361 11.0898 37.9616 10.8058C37.7871 10.5163 37.6998 10.1531 37.6998 9.71619V7.21749H38.8281V9.57692C38.8281 9.97016 38.9244 10.2651 39.117 10.4617C39.3156 10.6529 39.5954 10.7484 39.9565 10.7484C40.2213 10.7484 40.45 10.6993 40.6425 10.601C40.8411 10.5027 40.9946 10.3552 41.1029 10.1586C41.2172 9.96196 41.2744 9.71892 41.2744 9.42945V7.21749H42.4027V11.5923H41.3286V10.4125L41.5181 10.773C41.3556 11.057 41.1179 11.2755 40.805 11.4284C40.4921 11.5759 40.1491 11.6496 39.776 11.6496Z'
							fill='black'
						/>
						<path
							d='M45.6945 11.6496C45.1649 11.6496 44.7557 11.5267 44.4668 11.2809C44.178 11.0297 44.0335 10.6611 44.0335 10.175V6.25078H45.1619V10.1504C45.1619 10.3579 45.2191 10.5191 45.3334 10.6337C45.4538 10.7484 45.6192 10.8058 45.8299 10.8058C46.0826 10.8058 46.2932 10.7457 46.4617 10.6256L46.7777 11.3547C46.6453 11.453 46.4828 11.5267 46.2902 11.5759C46.0977 11.625 45.8991 11.6496 45.6945 11.6496ZM43.2392 8.06951V7.25026H46.4527V8.06951H43.2392Z'
							fill='black'
						/>
						<path
							d='M49.7459 11.6496C49.3367 11.6496 48.9756 11.5813 48.6627 11.4448C48.3498 11.3028 48.1061 11.0898 47.9315 10.8058C47.757 10.5163 47.6698 10.1531 47.6698 9.71619V7.21749H48.7981V9.57692C48.7981 9.97016 48.8944 10.2651 49.087 10.4617C49.2856 10.6529 49.5654 10.7484 49.9265 10.7484C50.1912 10.7484 50.4199 10.6993 50.6125 10.601C50.8111 10.5027 50.9645 10.3552 51.0728 10.1586C51.1872 9.96196 51.2444 9.71892 51.2444 9.42945V7.21749H52.3727V11.5923H51.2985V10.4125L51.4881 10.773C51.3256 11.057 51.0879 11.2755 50.775 11.4284C50.462 11.5759 50.119 11.6496 49.7459 11.6496Z'
							fill='black'
						/>
						<path
							d='M53.85 11.5923V7.21749H54.9242V8.42178L54.7979 8.06951C54.9423 7.77458 55.168 7.55065 55.4749 7.39773C55.7878 7.2448 56.1759 7.16834 56.6393 7.16834V8.14324C56.5912 8.13232 56.546 8.12685 56.5039 8.12685C56.4618 8.12139 56.4197 8.11866 56.3775 8.11866C55.9503 8.11866 55.6103 8.23336 55.3575 8.46274C55.1048 8.68667 54.9784 9.02256 54.9784 9.47042V11.5923H53.85Z'
							fill='black'
						/>
						<path
							d='M59.8181 11.6496C59.2825 11.6496 58.8132 11.554 58.41 11.3629C58.0128 11.1663 57.7029 10.8986 57.4802 10.56C57.2636 10.2214 57.1552 9.83635 57.1552 9.40488C57.1552 8.96795 57.2606 8.5829 57.4712 8.24974C57.6878 7.91112 57.9827 7.64623 58.3558 7.45507C58.7349 7.26392 59.1652 7.16834 59.6466 7.16834C60.116 7.16834 60.5343 7.26118 60.9013 7.44688C61.2684 7.63258 61.5573 7.89473 61.7679 8.23336C61.9785 8.57198 62.0838 8.97068 62.0838 9.42945C62.0838 9.47315 62.0808 9.5223 62.0748 9.57692C62.0748 9.63153 62.0718 9.68342 62.0658 9.73257H58.0489V9.0526H61.47L61.0277 9.26561C61.0337 9.01437 60.9766 8.79317 60.8562 8.60202C60.7358 8.41086 60.5704 8.26066 60.3597 8.15143C60.1551 8.0422 59.9174 7.98758 59.6466 7.98758C59.3698 7.98758 59.1261 8.0422 58.9155 8.15143C58.7109 8.26066 58.5484 8.41359 58.428 8.61021C58.3137 8.80137 58.2565 9.02802 58.2565 9.29018V9.45403C58.2565 9.71619 58.3227 9.94831 58.4551 10.1504C58.5875 10.3525 58.774 10.5081 59.0148 10.6174C59.2555 10.7266 59.5323 10.7812 59.8452 10.7812C60.116 10.7812 60.3597 10.743 60.5764 10.6665C60.793 10.5901 60.9856 10.4699 61.1541 10.306L61.7589 10.9369C61.5422 11.1663 61.2684 11.3438 60.9374 11.4694C60.6125 11.5895 60.2394 11.6496 59.8181 11.6496Z'
							fill='black'
						/>
						<path
							d='M33.5108 18.339V13.5054H31.4166V12.6043H36.7784V13.5054H34.6842V18.339H33.5108Z'
							fill='black'
						/>
						<path
							d='M39.3229 18.3963C38.7873 18.3963 38.3179 18.3008 37.9147 18.1096C37.5175 17.913 37.2076 17.6454 36.9849 17.3067C36.7683 16.9681 36.66 16.5831 36.66 16.1516C36.66 15.7147 36.7653 15.3296 36.9759 14.9965C37.1925 14.6578 37.4874 14.393 37.8605 14.2018C38.2396 14.0106 38.6699 13.9151 39.1513 13.9151C39.6207 13.9151 40.039 14.0079 40.4061 14.1936C40.7732 14.3793 41.062 14.6415 41.2726 14.9801C41.4833 15.3187 41.5886 15.7174 41.5886 16.1762C41.5886 16.2199 41.5856 16.269 41.5795 16.3236C41.5795 16.3783 41.5765 16.4301 41.5705 16.4793H37.5536V15.7993H40.9748L40.5324 16.0123C40.5385 15.7611 40.4813 15.5399 40.3609 15.3487C40.2406 15.1576 40.0751 15.0074 39.8645 14.8982C39.6599 14.7889 39.4222 14.7343 39.1513 14.7343C38.8745 14.7343 38.6308 14.7889 38.4202 14.8982C38.2156 15.0074 38.0531 15.1603 37.9327 15.3569C37.8184 15.5481 37.7612 15.7748 37.7612 16.0369V16.2008C37.7612 16.4629 37.8274 16.695 37.9598 16.8971C38.0922 17.0992 38.2788 17.2549 38.5195 17.3641C38.7602 17.4733 39.037 17.5279 39.3499 17.5279C39.6207 17.5279 39.8645 17.4897 40.0811 17.4132C40.2977 17.3368 40.4903 17.2166 40.6588 17.0528L41.2636 17.6836C41.047 17.913 40.7732 18.0905 40.4422 18.2161C40.1172 18.3363 39.7441 18.3963 39.3229 18.3963Z'
							fill='black'
						/>
						<path
							d='M44.884 18.3963C44.3785 18.3963 43.9271 18.3008 43.53 18.1096C43.1388 17.913 42.8319 17.6454 42.6092 17.3067C42.3866 16.9681 42.2752 16.5831 42.2752 16.1516C42.2752 15.7147 42.3866 15.3296 42.6092 14.9965C42.8319 14.6578 43.1388 14.393 43.53 14.2018C43.9271 14.0106 44.3785 13.9151 44.884 13.9151C45.3534 13.9151 45.7656 14.0024 46.1206 14.1772C46.4817 14.3465 46.7555 14.5978 46.9421 14.9309L46.0755 15.3897C45.9311 15.1822 45.7535 15.0292 45.5429 14.9309C45.3383 14.8326 45.1156 14.7835 44.8749 14.7835C44.5981 14.7835 44.3484 14.8381 44.1257 14.9473C43.9031 15.0565 43.7285 15.2149 43.6022 15.4225C43.4758 15.6246 43.4126 15.8676 43.4126 16.1516C43.4126 16.4356 43.4758 16.6814 43.6022 16.8889C43.7285 17.091 43.9031 17.2467 44.1257 17.3559C44.3484 17.4651 44.5981 17.5197 44.8749 17.5197C45.1156 17.5197 45.3383 17.4706 45.5429 17.3723C45.7535 17.274 45.9311 17.121 46.0755 16.9135L46.9421 17.3723C46.7555 17.7 46.4817 17.9539 46.1206 18.1342C45.7656 18.309 45.3534 18.3963 44.884 18.3963Z'
							fill='black'
						/>
						<path
							d='M50.6085 13.9151C50.9936 13.9151 51.3366 13.9833 51.6375 14.1199C51.9444 14.2564 52.1851 14.4667 52.3597 14.7507C52.5342 15.0292 52.6214 15.3897 52.6214 15.8321V18.339H51.4931V15.9632C51.4931 15.5754 51.3908 15.2859 51.1862 15.0948C50.9876 14.9036 50.7078 14.808 50.3467 14.808C50.0819 14.808 49.8472 14.8572 49.6426 14.9555C49.438 15.0538 49.2785 15.2013 49.1642 15.3979C49.0559 15.5891 49.0017 15.8321 49.0017 16.127V18.339H47.8734V12.2602H49.0017V15.1439L48.758 14.7835C48.9265 14.5049 49.1702 14.2919 49.4891 14.1445C49.8141 13.9915 50.1872 13.9151 50.6085 13.9151Z'
							fill='black'
						/>
						<path
							d='M56.7879 13.9151C57.173 13.9151 57.5161 13.9833 57.8169 14.1199C58.1239 14.2564 58.3646 14.4667 58.5391 14.7507C58.7136 15.0292 58.8009 15.3897 58.8009 15.8321V18.339H57.6725V15.9632C57.6725 15.5754 57.5702 15.2859 57.3656 15.0948C57.167 14.9036 56.8872 14.808 56.5261 14.808C56.2613 14.808 56.0266 14.8572 55.822 14.9555C55.6174 15.0538 55.458 15.2013 55.3436 15.3979C55.2353 15.5891 55.1811 15.8321 55.1811 16.127V18.339H54.0528V13.9642H55.127V15.1439L54.9374 14.7835C55.1059 14.5049 55.3496 14.2919 55.6686 14.1445C55.9935 13.9915 56.3667 13.9151 56.7879 13.9151Z'
							fill='black'
						/>
						<path
							d='M62.4075 18.3963C61.914 18.3963 61.4747 18.3008 61.0896 18.1096C60.7044 17.913 60.4005 17.6454 60.1779 17.3067C59.9552 16.9681 59.8439 16.5831 59.8439 16.1516C59.8439 15.7147 59.9552 15.3296 60.1779 14.9965C60.4005 14.6578 60.7044 14.393 61.0896 14.2018C61.4747 14.0106 61.914 13.9151 62.4075 13.9151C62.9069 13.9151 63.3493 14.0106 63.7344 14.2018C64.1256 14.393 64.4295 14.6551 64.6461 14.9883C64.8688 15.3214 64.9801 15.7092 64.9801 16.1516C64.9801 16.5831 64.8688 16.9681 64.6461 17.3067C64.4295 17.6454 64.1256 17.913 63.7344 18.1096C63.3493 18.3008 62.9069 18.3963 62.4075 18.3963ZM62.4075 17.5197C62.6843 17.5197 62.931 17.4651 63.1477 17.3559C63.3643 17.2467 63.5328 17.0883 63.6532 16.8807C63.7795 16.6732 63.8427 16.4301 63.8427 16.1516C63.8427 15.8676 63.7795 15.6246 63.6532 15.4225C63.5328 15.2149 63.3643 15.0565 63.1477 14.9473C62.931 14.8381 62.6873 14.7835 62.4165 14.7835C62.1397 14.7835 61.8929 14.8381 61.6763 14.9473C61.4657 15.0565 61.2972 15.2149 61.1708 15.4225C61.0444 15.6246 60.9812 15.8676 60.9812 16.1516C60.9812 16.4301 61.0444 16.6732 61.1708 16.8807C61.2972 17.0883 61.4657 17.2467 61.6763 17.3559C61.8929 17.4651 62.1367 17.5197 62.4075 17.5197Z'
							fill='black'
						/>
						<path
							d='M66.0591 18.339V12.2602H67.1874V18.339H66.0591Z'
							fill='black'
						/>
						<path
							d='M70.8348 18.3963C70.3413 18.3963 69.902 18.3008 69.5169 18.1096C69.1317 17.913 68.8278 17.6454 68.6052 17.3067C68.3825 16.9681 68.2712 16.5831 68.2712 16.1516C68.2712 15.7147 68.3825 15.3296 68.6052 14.9965C68.8278 14.6578 69.1317 14.393 69.5169 14.2018C69.902 14.0106 70.3413 13.9151 70.8348 13.9151C71.3342 13.9151 71.7766 14.0106 72.1617 14.2018C72.5529 14.393 72.8568 14.6551 73.0734 14.9883C73.2961 15.3214 73.4074 15.7092 73.4074 16.1516C73.4074 16.5831 73.2961 16.9681 73.0734 17.3067C72.8568 17.6454 72.5529 17.913 72.1617 18.1096C71.7766 18.3008 71.3342 18.3963 70.8348 18.3963ZM70.8348 17.5197C71.1116 17.5197 71.3583 17.4651 71.575 17.3559C71.7916 17.2467 71.9601 17.0883 72.0805 16.8807C72.2068 16.6732 72.27 16.4301 72.27 16.1516C72.27 15.8676 72.2068 15.6246 72.0805 15.4225C71.9601 15.2149 71.7916 15.0565 71.575 14.9473C71.3583 14.8381 71.1146 14.7835 70.8438 14.7835C70.567 14.7835 70.3202 14.8381 70.1036 14.9473C69.893 15.0565 69.7245 15.2149 69.5981 15.4225C69.4717 15.6246 69.4085 15.8676 69.4085 16.1516C69.4085 16.4301 69.4717 16.6732 69.5981 16.8807C69.7245 17.0883 69.893 17.2467 70.1036 17.3559C70.3202 17.4651 70.564 17.5197 70.8348 17.5197Z'
							fill='black'
						/>
						<path
							d='M76.6708 19.9857C76.2255 19.9857 75.7892 19.9311 75.362 19.8218C74.9407 19.7181 74.5947 19.5651 74.3239 19.363L74.8294 18.593C75.04 18.7513 75.3048 18.877 75.6237 18.9698C75.9487 19.0681 76.2767 19.1173 76.6076 19.1173C77.1372 19.1173 77.5254 19.0053 77.7721 18.7814C78.0188 18.5629 78.1422 18.2325 78.1422 17.7901V17.0118L78.2325 16.0205L78.1964 15.0292V13.9642H79.2705V17.6754C79.2705 18.4673 79.0479 19.049 78.6026 19.4204C78.1572 19.7972 77.5133 19.9857 76.6708 19.9857ZM76.5264 18.1424C76.0691 18.1424 75.6538 18.055 75.2807 17.8802C74.9136 17.7 74.6218 17.4515 74.4051 17.1347C74.1945 16.8179 74.0892 16.4465 74.0892 16.0205C74.0892 15.6 74.1945 15.2313 74.4051 14.9145C74.6218 14.5978 74.9136 14.352 75.2807 14.1772C75.6538 14.0024 76.0691 13.9151 76.5264 13.9151C76.9356 13.9151 77.3027 13.9888 77.6277 14.1363C77.9526 14.2837 78.2114 14.5131 78.404 14.8244C78.6026 15.1357 78.7019 15.5344 78.7019 16.0205C78.7019 16.5066 78.6026 16.908 78.404 17.2248C78.2114 17.5361 77.9526 17.7682 77.6277 17.9212C77.3027 18.0686 76.9356 18.1424 76.5264 18.1424ZM76.6979 17.274C76.9808 17.274 77.2335 17.2221 77.4562 17.1183C77.6788 17.0091 77.8503 16.8616 77.9707 16.6759C78.0971 16.4848 78.1602 16.2663 78.1602 16.0205C78.1602 15.7748 78.0971 15.559 77.9707 15.3733C77.8503 15.1822 77.6788 15.0374 77.4562 14.9391C77.2335 14.8354 76.9808 14.7835 76.6979 14.7835C76.4151 14.7835 76.1593 14.8354 75.9306 14.9391C75.708 15.0374 75.5335 15.1822 75.4071 15.3733C75.2867 15.559 75.2266 15.7748 75.2266 16.0205C75.2266 16.2663 75.2867 16.4848 75.4071 16.6759C75.5335 16.8616 75.708 17.0091 75.9306 17.1183C76.1593 17.2221 76.4151 17.274 76.6979 17.274Z'
							fill='black'
						/>
						<path
							d='M80.754 18.339V13.9642H81.8823V18.339H80.754ZM81.3226 13.2433C81.112 13.2433 80.9375 13.1832 80.7991 13.063C80.6667 12.9429 80.6005 12.7982 80.6005 12.6288C80.6005 12.4541 80.6667 12.3093 80.7991 12.1946C80.9375 12.0745 81.112 12.0144 81.3226 12.0144C81.5333 12.0144 81.7048 12.0718 81.8372 12.1865C81.9756 12.2957 82.0448 12.435 82.0448 12.6043C82.0448 12.7845 81.9786 12.9374 81.8462 13.063C81.7138 13.1832 81.5393 13.2433 81.3226 13.2433Z'
							fill='black'
						/>
						<path
							d='M85.629 18.3963C85.0934 18.3963 84.624 18.3008 84.2208 18.1096C83.8236 17.913 83.5137 17.6454 83.291 17.3067C83.0744 16.9681 82.9661 16.5831 82.9661 16.1516C82.9661 15.7147 83.0714 15.3296 83.282 14.9965C83.4986 14.6578 83.7935 14.393 84.1666 14.2018C84.5457 14.0106 84.976 13.9151 85.4574 13.9151C85.9268 13.9151 86.3451 14.0079 86.7122 14.1936C87.0793 14.3793 87.3681 14.6415 87.5787 14.9801C87.7894 15.3187 87.8947 15.7174 87.8947 16.1762C87.8947 16.2199 87.8917 16.269 87.8856 16.3236C87.8856 16.3783 87.8826 16.4301 87.8766 16.4793H83.8597V15.7993H87.2808L86.8385 16.0123C86.8446 15.7611 86.7874 15.5399 86.667 15.3487C86.5467 15.1576 86.3812 15.0074 86.1706 14.8982C85.966 14.7889 85.7282 14.7343 85.4574 14.7343C85.1806 14.7343 84.9369 14.7889 84.7263 14.8982C84.5217 15.0074 84.3592 15.1603 84.2388 15.3569C84.1245 15.5481 84.0673 15.7748 84.0673 16.0369V16.2008C84.0673 16.4629 84.1335 16.695 84.2659 16.8971C84.3983 17.0992 84.5849 17.2549 84.8256 17.3641C85.0663 17.4733 85.3431 17.5279 85.656 17.5279C85.9268 17.5279 86.1706 17.4897 86.3872 17.4132C86.6038 17.3368 86.7964 17.2166 86.9649 17.0528L87.5697 17.6836C87.3531 17.913 87.0793 18.0905 86.7483 18.2161C86.4233 18.3363 86.0502 18.3963 85.629 18.3963Z'
							fill='black'
						/>
						<path
							d='M90.4679 18.3963C90.0647 18.3963 89.6766 18.3499 89.3035 18.2571C88.9364 18.1588 88.6445 18.0413 88.4279 17.9048L88.8612 17.1265C89.0778 17.2521 89.3366 17.3559 89.6375 17.4378C89.9384 17.5197 90.2392 17.5607 90.5401 17.5607C90.8952 17.5607 91.1509 17.517 91.3074 17.4296C91.4699 17.3422 91.5511 17.2248 91.5511 17.0774C91.5511 16.9572 91.497 16.8671 91.3886 16.807C91.2803 16.7415 91.1389 16.6923 90.9644 16.6595C90.7899 16.6268 90.5943 16.5967 90.3777 16.5694C90.167 16.5421 89.9534 16.5066 89.7368 16.4629C89.5261 16.4138 89.3336 16.3455 89.159 16.2581C88.9845 16.1653 88.8431 16.0424 88.7348 15.8894C88.6265 15.7365 88.5723 15.5344 88.5723 15.2832C88.5723 15.0047 88.6596 14.7643 88.8341 14.5623C89.0086 14.3547 89.2523 14.1963 89.5652 14.0871C89.8842 13.9724 90.2603 13.9151 90.6936 13.9151C91.0186 13.9151 91.3465 13.9478 91.6775 14.0134C92.0085 14.0789 92.2823 14.1718 92.4989 14.2919L92.0657 15.0702C91.837 14.9446 91.6053 14.8599 91.3706 14.8162C91.1419 14.7671 90.9132 14.7425 90.6846 14.7425C90.3415 14.7425 90.0858 14.7889 89.9173 14.8818C89.7548 14.9746 89.6736 15.092 89.6736 15.234C89.6736 15.3651 89.7277 15.4634 89.836 15.529C89.9444 15.5945 90.0858 15.6464 90.2603 15.6846C90.4348 15.7229 90.6274 15.7556 90.838 15.7829C91.0547 15.8048 91.2683 15.8403 91.4789 15.8894C91.6895 15.9386 91.8821 16.0069 92.0566 16.0943C92.2372 16.1762 92.3816 16.2936 92.4899 16.4465C92.5982 16.5995 92.6524 16.7988 92.6524 17.0446C92.6524 17.3177 92.5621 17.5552 92.3816 17.7573C92.2071 17.9594 91.9573 18.1178 91.6324 18.2325C91.3074 18.3417 90.9193 18.3963 90.4679 18.3963Z'
							fill='black'
						/>
						<path
							d='M35.1807 25.6495C34.6873 25.6495 34.2329 25.5785 33.8177 25.4365C33.4085 25.2891 33.0504 25.0843 32.7435 24.8221C32.4366 24.5545 32.1989 24.2404 32.0304 23.88C31.8619 23.5195 31.7776 23.1263 31.7776 22.7003C31.7776 22.2743 31.8619 21.881 32.0304 21.5206C32.1989 21.1601 32.4366 20.8488 32.7435 20.5866C33.0564 20.319 33.4205 20.1142 33.8357 19.9722C34.251 19.8247 34.7053 19.751 35.1988 19.751C35.7344 19.751 36.2158 19.8302 36.643 19.9886C37.0763 20.147 37.4404 20.3791 37.7353 20.6849L36.9951 21.3403C36.7484 21.1164 36.4806 20.9525 36.1917 20.8488C35.9089 20.7395 35.5959 20.6849 35.2529 20.6849C34.9219 20.6849 34.615 20.7341 34.3322 20.8324C34.0494 20.9307 33.8056 21.07 33.601 21.2502C33.3964 21.4304 33.237 21.6434 33.1226 21.8892C33.0143 22.135 32.9601 22.4053 32.9601 22.7003C32.9601 22.9897 33.0143 23.2574 33.1226 23.5031C33.237 23.7489 33.3964 23.9646 33.601 24.1503C33.8056 24.3306 34.0464 24.4698 34.3232 24.5681C34.6 24.6665 34.9069 24.7156 35.2439 24.7156C35.5568 24.7156 35.8577 24.6719 36.1466 24.5845C36.4414 24.4917 36.7213 24.3388 36.9861 24.1258L37.654 24.9204C37.3231 25.1607 36.9379 25.3437 36.4986 25.4693C36.0653 25.5895 35.626 25.6495 35.1807 25.6495ZM36.5438 24.7812V22.6347H37.654V24.9204L36.5438 24.7812Z'
							fill='black'
						/>
						<path
							d='M39.0935 25.5676V21.1929H40.1676V22.3971L40.0413 22.0449C40.1857 21.7499 40.4114 21.526 40.7183 21.3731C41.0312 21.2202 41.4193 21.1437 41.8827 21.1437V22.1186C41.8346 22.1077 41.7894 22.1022 41.7473 22.1022C41.7052 22.0968 41.6631 22.094 41.6209 22.094C41.1937 22.094 40.8537 22.2087 40.6009 22.4381C40.3482 22.662 40.2218 22.9979 40.2218 23.4458V25.5676H39.0935Z'
							fill='black'
						/>
						<path
							d='M44.9622 25.625C44.4688 25.625 44.0295 25.5294 43.6443 25.3382C43.2592 25.1416 42.9553 24.874 42.7326 24.5354C42.51 24.1968 42.3986 23.8117 42.3986 23.3802C42.3986 22.9433 42.51 22.5583 42.7326 22.2251C42.9553 21.8865 43.2592 21.6216 43.6443 21.4304C44.0295 21.2393 44.4688 21.1437 44.9622 21.1437C45.4617 21.1437 45.904 21.2393 46.2892 21.4304C46.6803 21.6216 46.9842 21.8838 47.2009 22.2169C47.4235 22.5501 47.5349 22.9378 47.5349 23.3802C47.5349 23.8117 47.4235 24.1968 47.2009 24.5354C46.9842 24.874 46.6803 25.1416 46.2892 25.3382C45.904 25.5294 45.4617 25.625 44.9622 25.625ZM44.9622 24.7484C45.2391 24.7484 45.4858 24.6938 45.7024 24.5845C45.9191 24.4753 46.0876 24.3169 46.2079 24.1094C46.3343 23.9018 46.3975 23.6588 46.3975 23.3802C46.3975 23.0962 46.3343 22.8532 46.2079 22.6511C46.0876 22.4436 45.9191 22.2852 45.7024 22.1759C45.4858 22.0667 45.2421 22.0121 44.9713 22.0121C44.6944 22.0121 44.4477 22.0667 44.2311 22.1759C44.0205 22.2852 43.852 22.4436 43.7256 22.6511C43.5992 22.8532 43.536 23.0962 43.536 23.3802C43.536 23.6588 43.5992 23.9018 43.7256 24.1094C43.852 24.3169 44.0205 24.4753 44.2311 24.5845C44.4477 24.6938 44.6914 24.7484 44.9622 24.7484Z'
							fill='black'
						/>
						<path
							d='M50.6539 25.625C50.2447 25.625 49.8836 25.5567 49.5707 25.4202C49.2577 25.2782 49.014 25.0652 48.8395 24.7812C48.665 24.4917 48.5777 24.1285 48.5777 23.6916V21.1929H49.7061V23.5523C49.7061 23.9455 49.8024 24.2404 49.9949 24.4371C50.1935 24.6282 50.4733 24.7238 50.8344 24.7238C51.0992 24.7238 51.3279 24.6746 51.5204 24.5763C51.719 24.478 51.8725 24.3306 51.9808 24.1339C52.0951 23.9373 52.1523 23.6943 52.1523 23.4048V21.1929H53.2807V25.5676H52.2065V24.3879L52.396 24.7484C52.2336 25.0324 51.9959 25.2509 51.6829 25.4038C51.37 25.5512 51.027 25.625 50.6539 25.625Z'
							fill='black'
						/>
						<path
							d='M57.4299 25.625C57.0388 25.625 56.6807 25.543 56.3557 25.3792C56.0368 25.2154 55.781 24.9696 55.5885 24.6419C55.4019 24.3087 55.3086 23.8882 55.3086 23.3802C55.3086 22.8668 55.3989 22.4463 55.5794 22.1186C55.766 21.7909 56.0187 21.5479 56.3377 21.3895C56.6566 21.2256 57.0207 21.1437 57.4299 21.1437C57.9053 21.1437 58.3236 21.2365 58.6846 21.4222C59.0517 21.6079 59.3406 21.8674 59.5512 22.2005C59.7679 22.5337 59.8762 22.9269 59.8762 23.3802C59.8762 23.8336 59.7679 24.2295 59.5512 24.5681C59.3406 24.9013 59.0517 25.1607 58.6846 25.3464C58.3236 25.5321 57.9053 25.625 57.4299 25.625ZM54.758 27.157V21.1929H55.8322V22.2251L55.7961 23.3884L55.8864 24.5518V27.157H54.758ZM57.3036 24.7484C57.5744 24.7484 57.8151 24.6938 58.0257 24.5845C58.2423 24.4753 58.4138 24.3169 58.5402 24.1094C58.6666 23.9018 58.7298 23.6588 58.7298 23.3802C58.7298 23.0962 58.6666 22.8532 58.5402 22.6511C58.4138 22.4436 58.2423 22.2852 58.0257 22.1759C57.8151 22.0667 57.5744 22.0121 57.3036 22.0121C57.0327 22.0121 56.789 22.0667 56.5724 22.1759C56.3557 22.2852 56.1842 22.4436 56.0579 22.6511C55.9315 22.8532 55.8683 23.0962 55.8683 23.3802C55.8683 23.6588 55.9315 23.9018 56.0579 24.1094C56.1842 24.3169 56.3557 24.4753 56.5724 24.5845C56.789 24.6938 57.0327 24.7484 57.3036 24.7484Z'
							fill='black'
						/>
					</g>
				</g>
			</svg> */}
		</footer>
	);
};
