import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	serviceListOfCurrentProduct: [],
};

export const servicesSlice = createSlice({
	name: 'services',
	initialState,

	reducers: {
		setServiceListOfCurrentProduct: (state, action) => {
			state.serviceListOfCurrentProduct = action.payload
				? action.payload
				: initialState.serviceListOfCurrentProduct;
		},
	},

	// extraReducers: {
	// },
});

export const { setServiceListOfCurrentProduct } = servicesSlice.actions;
export const servicesReducer = servicesSlice.reducer;
