import s from './operatingProcedureSection.module.scss';

export const OperatingProcedureSection = ({ procedureClasses }) => {
	const procedureList = [
		{
			name: 'Консультация',
			description:
				'Наш специалист связывается с вами для выявления потребностей вашего бизнеса',
		},
		{
			name: 'Аудит',
			description:
				'Проводим краткий ИТ-аудит для получения информации о состоянии IT-инфраструктуры и разрабатываем рекомендации по её улучшению',
		},
		{
			name: 'Формируем КП',
			description:
				'В коммерческом предложении подробно описываем все технические и организационные параметры и предоставляем оптимальный расчёт цены',
		},
		{
			name: 'Договор',
			description:
				'У нас прозрачный договор со строгим SLA, с зафиксированной финансовой ответственностью за качество оказываемых ИТ-услуг',
		},
	];

	return (
		<section className={s.operatingProcedure}>
			<h3 className='sectionTitle'>Порядок работы</h3>

			<div className={s.operatingProcedure_procedureList}>
				{procedureList.map((procedure, i) => (
					<Procedure
						key={i}
						procedureClasses={procedureClasses}
						number={++i}
						name={procedure.name}
						description={procedure.description}
					/>
				))}
			</div>
		</section>
	);
};

function Procedure({ procedureClasses, number, name, description }) {
	return (
		<div className={`${s.procedure} ${procedureClasses}`}>
			<h3>{number}</h3>
			<h4>{name}</h4>

			<p>{description}</p>
		</div>
	);
}
