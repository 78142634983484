import { memo } from 'react';
import s from './input.module.css';
// import { maxBy } from 'lodash';

export const Input = memo(
	({
		refInput,
		type,
		inputName,
		classes,
		inputValue,
		clickFunction,
		changeFunction,
		blurFunction,
		focusFunction,
		keyDownEnterFunction,
		placeholder,
		required,
		defaultCheckedINput,
		checked,
		disabled,
		id,
		maxLength,
		max,
		min,
	}) => {
		return (
			<input
				id={id}
				ref={refInput}
				type={type}
				name={inputName}
				className={`${s.input} ${classes}`}
				value={inputValue}
				onClick={clickFunction}
				onChange={changeFunction}
				onBlur={blurFunction}
				onFocus={focusFunction}
				onKeyDownCapture={(e) => {
					if (e.key === 'Enter') {
						keyDownEnterFunction();
					}
				}}
				placeholder={placeholder}
				required={required}
				defaultChecked={defaultCheckedINput}
				checked={checked}
				disabled={disabled}
				maxLength={maxLength}
				max={max}
				min={min}
			/>
		);
	}
);
