import s from './checkbox.module.scss';
import { ReactComponent as Check } from '../../../images/icons/checkbox.svg';
import { Input } from '../input/input';

export const Checkbox = ({ text, isChecked, changeFunction }) => {
	return (
		<label className={s.container}>
			{isChecked ? <Check /> : <div></div>}
			<Input
				type={'checkbox'}
				checked={isChecked}
				changeFunction={changeFunction}
			/>

			{text}
		</label>
	);
};
