import s from './zennolabPage.module.scss';
import { TitleSection } from '../../common/titleSection/titleSection';
import { FeedbackSection } from '../../common/feedbackSection/feedbackSection';
import { ReactComponent as Zennolab } from '../../../images/logo/zennolab.svg';
import computer from '../../../images/pictures/img3.png';
import { ProductSection } from './productSection/productSection';
import { ReactComponent as ZennoPoster } from '../../../images/logo/zennoPoster.svg';
import { ReactComponent as ZennoDroid } from '../../../images/logo/zennoDroid.svg';
import { ReactComponent as ZennoProxyChecker } from '../../../images/logo/zennoProxyChecker.svg';
import zPoster from '../../../images/pictures/zennoPoster.png';
import zDroid from '../../../images/pictures/zennoDroid.png';
import zProxyChecker from '../../../images/pictures/zennoProxyChecker.png';

export const ZennolabPage = () => {
	return (
		<div className='pageContainer'>
			<TitleSection
				classes={s.titleSectionBgr}
				title={<Zennolab />}
				subtitle={
					'Решения для автоматизации деятельности веб-мастеров и SEO-специалистов'
				}
				text={'Наша компания принимала участие в разработке продуктов:'}
				img={<img src={computer} alt='img' />}
			/>

			<ProductSection
				icon={<ZennoPoster />}
				title={'ZennoPoster'}
				description={
					'ZennoPoster предназначен для SEO-специалистов, вебмастеров и людей, которые ведут активную деятельность в интернете. Программа умеет записывать действия человека на интернет-ресурсах (заполнение форм, клики по ссылкам) и повторять их в десятки и сотни потоков (Professional Version). А также обеспечивает анонимность через прокси, которые обрабатываются мощным проксичекером'
				}
				pathLink={'https://zennolab.com/ru/products/zennoposter/'}
				img={zPoster}
			/>

			<ProductSection
				icon={<ZennoDroid />}
				title={'ZennoDroid'}
				description={
					'В ZennoDroid вы можете записывать действия, совершаемые мышью на виртуальном экране Android, и затем воспроизвести эти действия в том же порядке. Это будет выглядеть так, будто вы водите пальцем по экрану смартфона. Мы включили в программу возможность смены прокси и установки некоторых системных настроек для того, чтобы вы смогли представляться каждый раз разными устройствами'
				}
				pathLink={'https://zennolab.com/ru/products/zennodroid/'}
				img={zDroid}
			/>

			<ProductSection
				icon={<ZennoProxyChecker />}
				title={'ZennoProxyChecker'}
				description={
					'ZennoProxyChecker — это решение, обеспечивающее полный цикл работы с прокси: автоматический поиск, загрузка с веб-страниц, хранение в базе, проверка по множеству параметров (тип, https, скорость и т.д.), выдача различными способами (файл, http, ftp). Имеется возможность проверки доступа на любой сайт. Скорость работы ограничена только мощностью вашего PC или сервера и пропускной способностью интернет-канала, и может достигать несколько десятков тысяч потоков'
				}
				pathLink={'https://zennolab.com/ru/products/zennoproxychecker/'}
				img={zProxyChecker}
			/>
			<FeedbackSection />
		</div>
	);
};
