import s from './ourServices.module.scss';
// import { ReactComponent as Im } from '../../../images/logo/im.svg';
import { useNavigate } from 'react-router-dom';
import { ServiceBlock } from './serviceBlock/serviceBlock';
import outsourcingBlockImg from '../../../../images/pictures/img1.png';
import developmentBlockImg from '../../../../images/pictures/img2.png';

export const OurServices = () => {
	const navigate = useNavigate();

	const outsourcingServiceList = [
		{
			name: 'Обслуживание серверов',
			description:
				'Поддержка, удаленное администрирование и мониторинг серверов. Устранение причин сбоя, снижение времени простоя',
		},
		{
			name: 'Виртуальные сервера и приложения',
			description:
				'Внедрение и администрирование систем виртуализации. Увеличение гибкости и эффективности использования ресурсов',
		},
		{
			name: 'Администрирование баз данных',
			description:
				'Внедрение, оптимизация и обеспечение безопасности баз данных',
		},
		{
			name: 'Кластеризация серверов',
			description:
				'Объединение нескольких серверов в единую группу для обеспечения надёжности и производительности',
		},
		{
			name: 'Резервное копирование данных',
			description:
				'Обеспечение резервного копирования и восстановление информации при необходимости',
		},
		{
			name: 'Управление доступами',
			description:
				'Создание, разграничение доступов, модификация учётных записей и групп пользователей',
		},
		{
			name: 'Настройка удаленного доступа',
			description:
				'Организация совместной удаленной работы через защищенное VPN соединение',
		},
		{
			name: 'Корпоративные данные',
			description:
				'Защита от потери информации после аварий, непреднамеренных и преднамеренных действий пользователей',
		},
	];
	const developmentServiceList = [
		{
			name: 'Веб-сайты',
			description:
				'Официальный веб-ресурс, задачей которого является доведение тематической информации до посетителя сайта',
		},
		{
			name: 'Лендинги',
			description:
				'Одностраничный сайт с индивидуальным дизайном, уникальными текстами и высокой конверсией в заявку',
		},
		{
			name: 'Интернет магазин',
			description: 'Веб-сайт с функцией приобретения товара или услуги онлайн',
		},
		{
			name: 'Разработка ПО',
			description:
				'Работа со всеми типами операционных систем, включая гибридные системы и кросс-платформенную поддержку',
		},
		{
			name: 'Разработка облачных сервисов',
			description:
				'Предоставление комплексных услуг по разработке веб-приложений и облачных сервисов',
		},
		{
			name: 'Бизнес/системный анализ',
			description:
				'Бизнес-аналитики и системные аналитики тщательно изучают требования и анализируют каждую деталь',
		},
	];

	return (
		<section className={s.ourServices}>
			<h2 className='sectionTitle'>Наши услуги</h2>

			<ServiceBlock
				classes={s.outsourcingBlock}
				title={'Услуги IT-Аутсорсинга'}
				subtitle={
					'Возьмем под полный контроль вашу IT-инфраструкруру снизим затраты и обеспечим полную безопасность'
				}
				serviceList={outsourcingServiceList}
				img={outsourcingBlockImg}
				navigateFunction={() => navigate('/outsourcing')}
			/>

			<ServiceBlock
				classes={s.developmentBlock}
				title={'Разработка сайтов и ПО'}
				subtitle={
					'Информационная механика разрабатывает качественное программное обеспечение и реализует любые решения: от простой кастомизации до разработки полного цикла.'
				}
				serviceList={developmentServiceList}
				img={developmentBlockImg}
				navigateFunction={() => navigate('/development')}
			/>
		</section>
	);
};
