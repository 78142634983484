import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import s from './rightPanel.module.scss';
import { ReactComponent as Office } from '../../../images/icons/office.svg';
import { ReactComponent as Im } from '../../../images/logo/im.svg';
import { ReactComponent as Zennolab } from '../../../images/logo/zennolab.svg';
import { ReactComponent as AdaptiveSearch } from '../../../images/logo/adaptiveSearch.svg';
import { ReactComponent as Development } from '../../../images/icons/development.svg';
import { ReactComponent as Server } from '../../../images/icons/server.svg';
import { Login } from '../../common/login/login';
import { selectUser } from '../../../store/selectors';
import { setUser } from '../../../store/features/user/userSlice';
import { deleteCookie } from '../../../core/utils/functions/cookie/deleteCookie';

export const RightPanel = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const refPanel = useRef();
	const refMenuToggle = useRef();

	const userData = useSelector(selectUser);

	const [isOpenLogin, setIsOpenLogin] = useState(false);

	const setOpenMenu = () => {
		refPanel.current.classList.toggle(
			s.rightPanelBlock_rightPanelWrp_rightPanel_open
		);
	};

	const handlerNavigate = (path = null) => {
		navigate(path);
		setOpenMenu();
		refMenuToggle.current.checked = false;
	};

	const handlerExit = () => {
		dispatch(setUser());
		deleteCookie('userAuth');
		navigate('');
	};

	return (
		<section className={s.rightPanelBlock}>
			<div className={s.rightPanelBlock_rightPanelWrp}>
				<aside
					ref={refPanel}
					className={s.rightPanelBlock_rightPanelWrp_rightPanel}>
					<div
						style={
							userData.isVerifyPhone || userData.isVerifyEmail
								? {}
								: { justifyContent: 'center' }
						}>
						<button
							onClick={() =>
								userData.isVerifyPhone
									? handlerNavigate('office')
									: setIsOpenLogin(true)
							}>
							<Office />
							Личный кабинет
						</button>

						{(userData.isVerifyPhone || userData.isVerifyEmail) && (
							<button onClick={handlerExit}>Выйти</button>
						)}
					</div>

					<button onClick={() => handlerNavigate('/')}>
						<Im />
					</button>

					<div
						className={s.rightPanelBlock_rightPanelWrp_rightPanel_ourProjects}>
						<h5>Наши продукты</h5>

						<button onClick={() => handlerNavigate('zennolab')}>
							<Zennolab />
						</button>

						<a
							href='https://adaptivesearch.ru/'
							onClick={() => handlerNavigate()}
							target='_blank'>
							<AdaptiveSearch />

							<div>
								<span>A</span>daptive <span>S</span>earch
							</div>
						</a>

						<a
							href='http://justicia.ltd/'
							onClick={() => handlerNavigate()}
							target='_blank'>
							<span>1</span> юридический.рф
						</a>

						<button onClick={() => handlerNavigate('outsourcing')}>
							<Server />
							Поддержка серверов
						</button>

						<button onClick={() => handlerNavigate('development')}>
							<Development />
							Разработка сайтов
						</button>
					</div>

					<button onClick={() => handlerNavigate('legalInfo')}>
						Правовая информация
					</button>
				</aside>

				<button>
					<input
						ref={refMenuToggle}
						id={s.menuToggle}
						onChange={setOpenMenu}
						type='checkbox'
					/>
					<label className={s.menuToggleLabel} htmlFor={s.menuToggle}></label>
					<div className={s.burgerMenu}></div>
				</button>
			</div>

			{isOpenLogin && <Login closeWindow={() => setIsOpenLogin(false)} />}
		</section>
	);
};
