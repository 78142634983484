import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	id: null,
	// userType: null,
	email: '',
	firstName: '',
	lastName: '',
	middleName: '',
	phone: '',
	balance: null,
	isVerifyPhone: null,
	isVerifyEmail: null,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,

	reducers: {
		setUser: (state, action) =>
			(state = action.payload ? action.payload : initialState),

		setIsVerifyPhone: (state, action) => {
			state.isVerifyPhone = action.payload ? action.payload : initialState;
		},
	},

	// extraReducers: {
	// },
});

export const { setUser, setIsVerifyPhone } = userSlice.actions;
export const userReducer = userSlice.reducer;
