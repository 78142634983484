import s from './mainPage.module.scss';
import { TitleSection } from '../../common/titleSection/titleSection';
import { OurServices } from './ourServices/ourServices';
import { OurProjects } from './ourProjects/ourProjects';
import { FeedbackSection } from '../../common/feedbackSection/feedbackSection';

export const MainPage = () => {
	return (
		<div className={'pageContainer'}>
			<TitleSection
				classes={s.titleSectionBgr}
				title={'Делаем мир проще'}
				subtitle={
					'Гарантируем высокое качество оказываемых услуг и надежность предлагаемых решений.'
				}
				text={
					'Наша Компания предлагает широкий спектр продуктов и услуг в области информационных технологий. Мы специализируемся на разработке и поддержке программного обеспечения, создании сайтов и предоставлении технической поддержки.'
				}
			/>

			<OurServices />

			<OurProjects />

			<FeedbackSection />
		</div>
	);
};
