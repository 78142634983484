import s from './registerCard.module.scss';
import { Button } from '../../../common/button/button';
import { Input } from '../../../common/input/input';
import { ReactComponent as Arrow } from '../../../../images/icons/arrow.svg';
import { InputPassword } from '../../../common/input/inputPassword';
import licenseAgreement from '../../../../documents/Лицензионное_соглашение.pdf';
import privacyPolicy from '../../../../documents/Политика_конфиденциальности.pdf';
import offerSales from '../../../../documents/Оферта_продажи_программных_продуктов.pdf';
import { useState } from 'react';
import { Checkbox } from '../../checkbox/checkbox';

export const RegisterCard = ({
	backBtnFn,
	firstName,
	lastName,
	middleName,
	email,
	phone,
	password,
	repeatPassword,
	handlerFirstName,
	handlerLastName,
	handlerMiddleName,
	handlerEmail,
	handlerPhone,
	handlerPassword,
	handlerRepeatPassword,
	error,
}) => {
	const [isAgreeOne, setIsAgreeOne] = useState(false);
	const [isAgreeTwo, setIsAgreeTwo] = useState(false);

	return (
		<>
			<div className={`${s.btnBack}`} onClick={backBtnFn}>
				<Arrow />
				Вернуться
			</div>

			<h4>Регистрация</h4>

			<div className={s.inputBlock}>
				<Input
					inputValue={firstName}
					changeFunction={handlerFirstName}
					placeholder='Имя*'
					required={true}
				/>
				<Input
					inputValue={lastName}
					changeFunction={handlerLastName}
					placeholder='Фамилия*'
					required={true}
				/>
				<Input
					inputValue={middleName}
					changeFunction={handlerMiddleName}
					placeholder='Отчество'
					required={false}
				/>
				<Input
					type={'email'}
					inputValue={email}
					changeFunction={handlerEmail}
					placeholder='Почта*'
					required={true}
				/>
				<Input
					type={'tel'}
					inputValue={phone}
					changeFunction={handlerPhone}
					placeholder='+7 (ХХХ) ХХХ ХХ-ХХ'
					maxLength={18}
					required={true}
				/>

				<InputPassword
					inputValue={password}
					changeFunction={handlerPassword}
					placeholder='Пароль*'
					required={true}
				/>
				<InputPassword
					inputValue={repeatPassword}
					changeFunction={handlerRepeatPassword}
					placeholder='Повторите пароль*'
					required={true}
				/>
			</div>

			{error && <div className={s.error}>{error}</div>}
			<div className={s.entryBlock}>
				<Button
					type='submit'
					classes={s.btn}
					text={<>Зарегистрироваться</>}
					disabled={!(isAgreeOne === true && isAgreeTwo === true)}
				/>

				<div>
					<Checkbox
						text={
							<p>
								Я согласен на обработку персональных данных и соглашаюсь c{' '}
								<a href={privacyPolicy} target='_blank'>
									политикой конфиденциальности
								</a>
							</p>
						}
						isChecked={isAgreeOne}
						changeFunction={() => setIsAgreeOne(!isAgreeOne)}
					/>

					<Checkbox
						text={
							<p>
								Я ознакомлен и соглашаюсь с условиями{' '}
								<a href={licenseAgreement} target='_blank'>
									Лицензионного соглашения
								</a>{' '}
								и{' '}
								<a href={offerSales} target='_blank'>
									Офертой продажи программных продуктов
								</a>
							</p>
						}
						isChecked={isAgreeTwo}
						changeFunction={() => setIsAgreeTwo(!isAgreeTwo)}
					/>
				</div>
			</div>

			{/* {!isVerify && (
				<>
					<p>Почта не подтверждена.</p>

					{resultVerify ? (
						<p>{resultVerify}</p>
					) : (
						<Button clickFunction={verified} text={'Подтвердить почту'} />
					)}
				</>
			)} */}
		</>
	);
};
