import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	publisherList: [],
};

export const publishersSlice = createSlice({
	name: 'publishers',
	initialState,

	reducers: {
		setPublisherList: (state, action) => {
			state.publisherList = action.payload
				? action.payload
				: initialState.publisherList;
		},
	},

	// extraReducers: {
	// },
});

export const { setPublisherList } = publishersSlice.actions;
export const publishersReducer = publishersSlice.reducer;
