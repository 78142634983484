import { useEffect } from 'react';
import { HistorySection } from './historySection/historySection';
import s from './officePage.module.scss';
import { PurchasingServices } from './purchasingServices/purchasingServices';
import { UserInfo } from './userInfo/userInfo';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectProducts,
	selectPublishers,
	selectUser,
} from '../../../store/selectors';
import { getPublisherList } from '../../../store/features/publisher/thunks';
import { getProductList } from '../../../store/features/products/thunks';
import { getCart } from '../../../store/features/cart/thunks';

export const OfficePage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userData = useSelector(selectUser);
	const { publisherList } = useSelector(selectPublishers);
	const { productList } = useSelector(selectProducts);

	useEffect(() => {
		if (userData.id === null) {
			navigate('/');
			return;
		}
	}, []);

	useEffect(() => {
		if (publisherList.length) return;

		dispatch(getPublisherList());
	}, []);

	useEffect(() => {
		if (productList.length) return;

		dispatch(getProductList());
	}, []);

	useEffect(() => {
		// console.log(userData.id);
		if (userData.id !== null) {
			dispatch(getCart(userData.id));
		}
	}, []);

	return (
		<div className='pageContainer'>
			<h2 className='pageTitle'>Личный кабинет</h2>

			<UserInfo />

			<PurchasingServices />

			<HistorySection />
		</div>
	);
};
