import { memo } from 'react';
import s from './formCard.module.css';

export const FormCard = memo(
	({ classes, title, content, submitFunction = () => {} }) => {
		const handlerSubmit = (e) => {
			e.preventDefault();
			// console.log(submitFunction);
			submitFunction();
		};

		return (
			<form className={`${classes} ${s.form}`} onSubmit={handlerSubmit}>
				{title && <h4 className={`${s.title}`}>{title}</h4>}

				{content}
			</form>
		);
	}
);
