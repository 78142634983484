import s from './developmentPage.module.scss';
import { TitleSection } from '../../../common/titleSection/titleSection';
import { ServicesSection } from '../servicesSection/servicesSection';
import { OperatingProcedureSection } from '../operatingProcedureSection/operatingProcedureSection';
import { YouWillGetSection } from '../youWillGetSection/youWillGetSection';
import { FeedbackSection } from '../../../common/feedbackSection/feedbackSection';

export const DevelopmentPage = () => {
	const serviceList = [
		{
			name: 'Веб-сайты',
			description:
				'Официальный веб-ресурс, задачей которого является доведение тематической информации до посетителя сайта',
		},
		{
			name: 'Лендинги',
			description:
				'Одностраничный сайт с индивидуальным дизайном, уникальными текстами и высокой конверсией в заявку',
		},
		{
			name: 'Интернет магазин',
			description: 'Веб-сайт с функцией приобретения товара или услуги онлайн',
		},
		{
			name: 'Разработка ПО',
			description:
				'Работа со всеми типами операционных систем, включая гибридные системы и кросс-платформенную поддержку',
		},
		{
			name: 'Разработка облачных сервисов',
			description:
				'Предоставление комплексных услуг по разработке веб-приложений и облачных сервисов',
		},
		{
			name: 'Бизнес/системный анализ',
			description:
				'Бизнес-аналитики и системные аналитики тщательно изучают требования и анализируют каждую деталь',
		},
	];

	return (
		<div className='pageContainer'>
			<TitleSection
				classes={s.titleSectionBgr}
				title={'Разработка сайтов и ПО'}
				subtitle={
					'Информационная механика разрабатывает качественное программное обеспечение и реализует любые решения: от простой кастомизации до разработки полного цикла.'
				}
				text={
					'Разрабатываем сайты любой сложности: от одностраничников-визиток и лендингов, до интернет-порталов и сервисов. Корпоративные сайты и интернет-магазины «под ключ» со всеми необходимыми интеграциями.'
				}
			/>

			<ServicesSection serviceClasses={s.service} serviceList={serviceList} />

			<OperatingProcedureSection procedureClasses={s.procedure} />

			<YouWillGetSection cardClasses={s.card} />

			<FeedbackSection />
		</div>
	);
};
