import { createAsyncThunk } from '@reduxjs/toolkit';
import { servicesApi } from '../../../core/api/api';
import { setServiceListOfCurrentProduct } from './servicesSlice';

export const getServiceListOfCurrentProduct = createAsyncThunk(
	'services/getServiceListOfCurrentProduct',
	async (productId, { rejectWithValue, dispatch }) => {
		servicesApi
			.getServiceListOfCurrentProduct(productId)
			.then((res) => {
				// console.log(res);
				dispatch(setServiceListOfCurrentProduct(res.data.services));
			})
			.catch((err) => console.log(err));
	}
);
