import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './purchasingServices.module.scss';
import { ReactComponent as Zennolab } from '../../../../images/logo/zennolab.svg';
import { ReactComponent as AdaptiveSearch } from '../../../../images/logo/adaptiveSearch.svg';
import { ReactComponent as Server } from '../../../../images/icons/server.svg';
import { ReactComponent as Development } from '../../../../images/icons/development.svg';
import { ReactComponent as Arrow } from '../../../../images/icons/arrow.svg';
import { ReactComponent as Attention } from '../../../../images/icons/attention.svg';
import { Button } from '../../../common/button/button';
import { ModalWindow } from '../../../common/modalWindow/modalWindow';
import { ServicesModalWindow } from './servicesModalWindow/servicesModalWindow';
import {
	selectCart,
	selectProducts,
	selectPublishers,
	selectServices,
	selectUser,
} from '../../../../store/selectors';
import { getServiceListOfCurrentProduct } from '../../../../store/features/services/thunks';
import { updateCart } from '../../../../store/features/cart/thunks';
import { debounce } from '../../../../core/utils/functions/debounce/debounce';

export const PurchasingServices = () => {
	const dispatch = useDispatch();
	const { id: userId } = useSelector(selectUser);
	const { publisherList } = useSelector(selectPublishers);
	const { productList } = useSelector(selectProducts);
	const { serviceListOfCurrentProduct } = useSelector(selectServices);
	const { cart } = useSelector(selectCart);

	const [currentPublisher, setCurrentPublisher] = useState(null);
	const [currentProductId, setCurrentProductId] = useState('');
	const [currentRedirectLink, setCurrentRedirectLink] = useState('');
	const [selectedServiceId, setSelectedServiceId] = useState(null);
	const [openModalServices, setOpenModalServices] = useState(false);
	const [totalPrice, setTotalPrice] = useState(0);

	const renderPublishers = (publisher) => {
		let logo = null;
		switch (publisher.id) {
			case 1:
				logo = <Zennolab />;
				break;
			case 2:
				logo = (
					<>
						<AdaptiveSearch />
						<p>
							<span>A</span>daptive <span>S</span>earch
						</p>
					</>
				);
				break;
			case 3:
				logo = (
					<p>
						<span>1</span> юридический.рф
					</p>
				);
				break;
			case 4:
				logo = (
					<>
						<Server />
						<p>Поддержка серверов</p>
					</>
				);
				break;
			case 5:
				logo = (
					<>
						<Development /> <p>Разработка сайтов</p>
					</>
				);
				break;
			default:
				logo = <></>;
				break;
		}

		return (
			<Publisher
				key={publisher.id}
				logo={logo}
				isSelect={currentPublisher?.id === publisher.id}
				clickFunction={() => handlerSetCurrentPublisher(publisher)}
			/>
		);
	};

	const renderProducts = (product) => {
		return (
			<option key={product.id} value={product.id}>
				{product.name}
			</option>
		);
	};

	const handlerSetCurrentPublisher = (publisher) => {
		setCurrentPublisher(publisher);
		setCurrentProductId('');
		handlerSetCurrentRedirectLink(publisher);
	};
	const handlerSetCurrentRedirectLink = (publisher) => {
		let currentLink = '';

		switch (publisher.id) {
			case 1:
				currentLink = 'https://zennolab.com/';
				break;

			case 2:
				currentLink = 'https://adaptivesearch.ru/';

				break;

			case 3:
				currentLink = 'http://justicia.ltd/';

				break;

			// case 5:
			// 	currentLink = '';
			// 	break;
		}

		setCurrentRedirectLink(currentLink);
	};

	const getServiceList = (e) => {
		if (!e.target.value) {
			setCurrentProductId(e.target.value);
			return;
		}

		if (+e.target.value === serviceListOfCurrentProduct[0]?.product_id) {
			setCurrentProductId(e.target.value);
			return;
		}

		setCurrentProductId(e.target.value);
		dispatch(getServiceListOfCurrentProduct(e.target.value));
	};

	const addServiceToCart = (service) => {
		const newCart = cart.map((el) => ({ ...el }));
		newCart.push({ ...service, count: 1 });
		dispatch(updateCart({ userId, newCart }));
	};

	const removeServiceFromCart = (service) => {
		const newCart = cart.filter((el) => el.id !== service.id);
		dispatch(updateCart({ userId, newCart }));
	};

	const setServiceCount = debounce((service, currentCount) => {
		if (currentCount === 0) {
			removeServiceFromCart(service);
			return;
		}

		const newCart = cart.map((el) => ({ ...el }));

		for (let el of newCart) {
			if (service.id === el.id) {
				// el.count += 1;
				el.count = currentCount;

				break;
			}
		}

		dispatch(updateCart({ userId, newCart }));
	}, 500);

	useEffect(() => {
		if (publisherList.length) {
			setCurrentPublisher(publisherList[0]);
			handlerSetCurrentRedirectLink(publisherList[0]);
		}
	}, [publisherList]);

	useEffect(() => {
		if (!cart.length) return setTotalPrice(0);

		let newTotalPrice = cart
			.reduce((sum, curr) => sum + curr.price_rub * curr.count, 0)
			.toFixed(2);

		setTotalPrice(newTotalPrice);
	}, [cart]);

	return (
		<section className={s.purchasing}>
			<h3 className='sectionTitle'>Покупка услуг</h3>

			<div className={s.purchasing_publisherBlock}>
				<h4>Выберите издателя:</h4>

				<div className={s.purchasing_publisherBlock_publisherList}>
					{publisherList.map(renderPublishers)}
				</div>
			</div>

			{currentPublisher?.id !== 4 && (
				<div className={s.purchasing_redirectBlock}>
					<div>
						<h3>
							<Attention /> Внимание
						</h3>

						<p>
							Уважаемый пользователь! <br /> Для приобретения продуктов и услуг
							данной торговой марки Вам необходимо перейти на указанный ресурс.
							Правила приобретения и стоимость указаны на данном ресурсе. Если
							Вы уверены в своём выборе и хотите продолжить, нажмите на кнопку
							«Перейти»
						</p>
					</div>

					<a href={currentRedirectLink} target='_blank'>
						<Button text='Перейти' />
					</a>
				</div>
			)}

			{currentPublisher?.id === 4 && (
				<>
					<div className={s.purchasing_productBlock}>
						<h4>Выберите продукт:</h4>

						<select
							className={s.purchasing_productBlock_list}
							onClick={getServiceList}>
							<option value=''>-- Выберите продукт --</option>

							{productList
								.filter(
									(product) => product.publisher_id === currentPublisher?.id
								)
								.map(renderProducts)}
						</select>
					</div>

					<div className={s.purchasing_servicePayBlock}>
						<h4>Выберите услуги:</h4>

						<Button
							text={'Нажмите, чтобы выбрать услугу'}
							clickFunction={() => setOpenModalServices(true)}
							disabled={!currentProductId}
						/>

						<div className={s.purchasing_servicePayBlock_cartBlock}>
							<h3 className='sectionTitle'>Корзина</h3>

							<div>
								{!cart.length && 'Корзина пуста'}

								{cart.map((service) => (
									<CartCard
										key={service.id}
										service={service}
										setServiceCount={setServiceCount}
										removeServiceFromCart={removeServiceFromCart}
									/>
								))}
							</div>
						</div>

						<div className={s.purchasing_servicePayBlock_payBlock}>
							<p>
								<span>К оплате:</span> {totalPrice} ₽
							</p>

							<Button text='Оплатить' clickFunction={() => {}} />
						</div>
					</div>

					{openModalServices && (
						<ModalWindow clickFunction={() => setOpenModalServices(false)}>
							<ServicesModalWindow
								serviceList={serviceListOfCurrentProduct}
								currentProduct={productList.find(
									(product) => product.id === +currentProductId
								)}
								cart={cart}
								addServiceToCart={addServiceToCart}
								setServiceCount={setServiceCount}
								removeServiceFromCart={removeServiceFromCart}
							/>
						</ModalWindow>
					)}
				</>
			)}
		</section>
	);
};

function Publisher({ logo, isSelect, clickFunction }) {
	return (
		<div
			className={`${s.publisher} ${isSelect ? s.active : ''}`}
			onClick={clickFunction}>
			{logo}
		</div>
	);
}

function CartCard({ service, setServiceCount, removeServiceFromCart }) {
	const [currentCount, setCurrentCount] = useState(service.count);

	const incrementCount = () => setCurrentCount((prev) => (prev += 1));

	const decrementCount = () => {
		if (currentCount <= 1) {
			removeServiceFromCart(service);
		}

		setCurrentCount((prev) => {
			if (prev === 0) return 0;

			return (prev -= 1);
		});
	};

	useEffect(() => {
		setCurrentCount(service.count);
	}, [service]);

	useEffect(() => {
		if (service.count === currentCount) return;

		setServiceCount(service, currentCount);
	}, [currentCount]);

	return (
		<div className={s.cartCard}>
			<p className={s.cartCard_name}>
				{service.product_name} / {service.name}
			</p>

			<div className={s.cartCard_description}>
				<p>{service.specs.map((el) => `${el.value}`).join('/')}</p>
			</div>

			<div className={s.cartCard_price}>
				<div className={s.cartCard_price_servicePrice}>
					<span>Стоимость:</span>{' '}
					<p>
						₽{service.price_rub.toFixed(2)} / {service.period}
					</p>
				</div>

				<div className={s.cartCard_price_totalPrice}>
					<span>Итого:</span>

					<div className={s.cartCard_price_totalPrice_count}>
						<button onClick={decrementCount}>
							<Arrow />
						</button>{' '}
						кол-во: {currentCount}
						<button onClick={incrementCount}>
							<Arrow />
						</button>
					</div>

					<p> ₽{(service.price_rub * service.count).toFixed(2)} </p>
				</div>
			</div>

			<div
				className={s.cartCard_cross}
				onClick={() => removeServiceFromCart(service)}></div>
		</div>
	);
}
