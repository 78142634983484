export const formatInputPhoneNumber = (value) => {
	if (!value) return value;
	// console.log('value', value, value.length);

	let phoneNumbersValue = value.replace(/[\D]/g, '');
	let phoneNumbersValueLength = phoneNumbersValue.length;
	let formattedPhoneNumber = '';

	// console.log('phoneNumber', phoneNumbersValue, phoneNumbersValueLength);

	if (['7', '8', '9'].includes(phoneNumbersValue[0])) {
		// Russian number
		if (phoneNumbersValue === '7' && value.length === 4) return '';

		formattedPhoneNumber =
			phoneNumbersValue[0] === '9' ? `+7 (${phoneNumbersValue}` : `+7 `;
		if (phoneNumbersValueLength > 1) {
			formattedPhoneNumber += `(${phoneNumbersValue.slice(1, 4)}`;
		}

		if (phoneNumbersValueLength > 4) {
			formattedPhoneNumber += `) ${phoneNumbersValue.slice(4, 7)}`;
		}

		if (phoneNumbersValueLength > 7) {
			formattedPhoneNumber += ` ${phoneNumbersValue.slice(7, 9)}`;
		}

		if (phoneNumbersValueLength > 9) {
			formattedPhoneNumber += `-${phoneNumbersValue.slice(9, 11)}`;
		}

		return formattedPhoneNumber;
	}

	// Not russian number
	if (phoneNumbersValueLength === 0) return '';

	return `+ ${phoneNumbersValue}`;
};
