import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	cart: [],
};

export const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		setCart: (state, action) =>
			(state = action.payload ? action.payload : initialState),
	},
});

export const { setCart } = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
