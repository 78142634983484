import { memo, useEffect, useState } from 'react';
import { Button } from '../../../../common/button/button';
import s from './servicesModalWindow.module.scss';
import { ReactComponent as Arrow } from '../../../../../images/icons/arrow.svg';

export const ServicesModalWindow = memo(
	({
		serviceList = [],
		currentProduct,
		cart = [],
		addServiceToCart,
		setServiceCount,
		removeServiceFromCart,
	}) => {
		const renderCurrentServiceList = () => {
			const map = new Map();
			serviceList.forEach((el) => map.set(el.id, { ...el }));

			cart.forEach((el) => {
				if (map.has(el.id)) {
					map.set(el.id, el);
				}
			});

			let currentServiceList = [];

			for (let val of map.values()) {
				currentServiceList.push(val);
			}

			return currentServiceList;
		};

		return (
			<section className={s.serviceWindow}>
				<h3 className='sectionTitle'>Выберите услугу</h3>

				<div className={s.serviceWindow_cardList}>
					{renderCurrentServiceList().map((service) => (
						<Card
							key={service.id}
							service={service}
							product={currentProduct}
							addServiceToCart={addServiceToCart}
							setServiceCount={setServiceCount}
							removeServiceFromCart={removeServiceFromCart}
						/>
					))}
				</div>
			</section>
		);
	}
);

function Card({
	service,
	addServiceToCart,
	setServiceCount,
	removeServiceFromCart,
}) {
	const [currentCount, setCurrentCount] = useState(service.count);

	const incrementCount = () => setCurrentCount((prev) => (prev += 1));

	const decrementCount = () => {
		if (currentCount <= 1) {
			removeServiceFromCart(service);
		}

		setCurrentCount((prev) => {
			if (prev === 0) return 0;

			return (prev -= 1);
		});
	};

	useEffect(() => {
		setCurrentCount(service.count);
	}, [service]);

	useEffect(() => {
		if (service.count === currentCount) return;

		setServiceCount(service, currentCount);
	}, [currentCount]);

	return (
		<div className={s.card}>
			<p>
				{service.product_name} / {service.name}
			</p>
			<span>{service.platform}</span>
			<p>
				₽{service.price_rub} <span>/ {service.period}</span>
			</p>
			{service.specs.map((el) => (
				<p key={el.id}>
					{el.value} <span>{el.type}</span>
				</p>
			))}

			{service.count ? (
				<div className={s.card_btnsWrp}>
					<button onClick={decrementCount}>
						<Arrow />
					</button>
					{/* кол-во: {service.count} */}
					кол-во: {currentCount}
					<button onClick={incrementCount}>
						<Arrow />
					</button>
				</div>
			) : (
				<Button
					text={'Выбрать'}
					clickFunction={() => {
						addServiceToCart(service);
					}}
				/>
			)}

			<div className={s.card_tags}>
				{service.tags.map((tag) => (
					<p key={tag.id}>{tag.name}</p>
				))}
			</div>
		</div>
	);
}
