import { Button } from '../../../common/button/button';
import s from './loginCard.module.scss';
import { ReactComponent as GosUslygiIcon } from '../../../../images/logo/gosUslygiIcon.svg';
import { ReactComponent as Arrow } from '../../../../images/icons/arrow.svg';
import licenseAgreement from '../../../../documents/Лицензионное_соглашение.pdf';
import privacyPolicy from '../../../../documents/Политика_конфиденциальности.pdf';
import offerSales from '../../../../documents/Оферта_продажи_программных_продуктов.pdf';
import { Checkbox } from '../../checkbox/checkbox';
import { useState } from 'react';

export const LoginCard = ({
	isBtnBack = false,
	navigateText,
	navigateClickFn,
	title,
	inputBlockContent,
	rememberBlockContent,
	btnText,
	isLoginOptionsBlock = true,
	openRegisterFn,
	error = '',
}) => {
	const [isAgreeOne, setIsAgreeOne] = useState(false);
	const [isAgreeTwo, setIsAgreeTwo] = useState(false);

	return (
		<>
			<div
				className={`${s.navigate} ${isBtnBack ? s.btnBack : ''}`}
				onClick={navigateClickFn}>
				{isBtnBack ? (
					<>
						<Arrow />
						{navigateText}
					</>
				) : (
					<>
						{navigateText}
						<Arrow />
					</>
				)}
			</div>

			<h4>{title}</h4>

			<div className={error ? s.inputError : s.inputBlock}>
				{inputBlockContent}
			</div>

			{error && <div className={s.error}>{error}</div>}

			<div className={s.rememberBlock}>{rememberBlockContent}</div>

			<div className={s.entryBlock}>
				<Button
					type='submit'
					classes={s.btn}
					text={<>{btnText}</>}
					disabled={!(isAgreeOne === true && isAgreeTwo === true)}
				/>

				<div>
					<Checkbox
						text={
							<p>
								Я согласен на обработку персональных данных и соглашаюсь c{' '}
								<a href={privacyPolicy} target='_blank'>
									политикой конфиденциальности
								</a>
							</p>
						}
						isChecked={isAgreeOne}
						changeFunction={() => setIsAgreeOne(!isAgreeOne)}
					/>

					<Checkbox
						text={
							<p>
								Я ознакомлен и соглашаюсь с условиями{' '}
								<a href={licenseAgreement} target='_blank'>
									Лицензионного соглашения
								</a>{' '}
								и{' '}
								<a href={offerSales} target='_blank'>
									Офертой продажи программных продуктов
								</a>
							</p>
						}
						isChecked={isAgreeTwo}
						changeFunction={() => setIsAgreeTwo(!isAgreeTwo)}
					/>
				</div>
			</div>

			{isLoginOptionsBlock && (
				<div className={s.loginOptionsBlock}>
					<p>Другие способы входа</p>

					<div className={s.icons}>
						<GosUslygiIcon />
						<GosUslygiIcon />
						<GosUslygiIcon />
						<GosUslygiIcon />
					</div>
				</div>
			)}

			{openRegisterFn && (
				<p className={s.registerBlock}>
					Нет учетной записи?{' '}
					<span onClick={openRegisterFn}>Зарегистрироваться</span>
				</p>
			)}
		</>
	);
};
