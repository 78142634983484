import { memo, useState } from 'react';
import s from './input.module.css';
import { ReactComponent as OpenEye } from '../../../images/icons/openEye.svg';
import { ReactComponent as CloseEye } from '../../../images/icons/closeEye.svg';

export const InputPassword = memo(
	({
		refInput,
		// type,
		inputName,
		classes,
		inputValue,
		clickFunction,
		changeFunction,
		blurFunction,
		focusFunction,
		keyDownEnterFunction,
		placeholder,
		required,
		defaultCheckedINput,
		checked,
		disabled,
		maxLength,
	}) => {
		const [isVisible, setIsVisible] = useState(false);

		const handlerVisible = () => setIsVisible(!isVisible);
		return (
			<div className={s.inputPassword}>
				<input
					ref={refInput}
					type={isVisible ? 'text' : 'password'}
					name={inputName}
					className={`${s.input} ${classes}`}
					value={inputValue}
					onClick={clickFunction}
					onChange={changeFunction}
					onBlur={blurFunction}
					onFocus={focusFunction}
					onKeyDownCapture={(e) => {
						if (e.key === 'Enter') {
							keyDownEnterFunction();
						}
					}}
					placeholder={placeholder}
					required={required}
					defaultChecked={defaultCheckedINput}
					checked={checked}
					disabled={disabled}
					maxLength={maxLength}
				/>

				{isVisible ? (
					<CloseEye onClick={handlerVisible} />
				) : (
					<OpenEye onClick={handlerVisible} />
				)}
			</div>
		);
	}
);
