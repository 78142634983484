// import { Service } from './service/service';
import s from './serviceBlock.module.scss';
import { ReactComponent as LinkArrow } from '../../../../../images/icons/arrow.svg';
import { ButtonArrow } from '../../../../common/buttonArrow/buttonArrow';

export const ServiceBlock = ({
	classes,
	title,
	subtitle,
	serviceList,
	img,
	navigateFunction,
}) => {
	return (
		<div className={`${s.servicesBlock} ${classes}`}>
			<div className={`${s.servicesBlock_titleBlock} `}>
				<h3 className='sectionTitle'>{title}</h3>

				<p>{subtitle}</p>
			</div>

			<img src={img} alt='img' />

			<div className={s.servicesBlock_serviceList}>
				{serviceList.map((service, i) => (
					<Service
						key={i}
						serviceName={service.name}
						description={service.description}
					/>
				))}
			</div>

			<span>
				Подробнее
				<ButtonArrow clickFun={navigateFunction} />
				{/* <button className='linkArrow' onClick={navigateFunction}>
					<LinkArrow />
				</button> */}
			</span>
		</div>
	);
};

function Service({ serviceName, description }) {
	return (
		<div className={s.service}>
			<h4>{serviceName}</h4>

			<p>{description}</p>
		</div>
	);
}
