import { createContext } from 'react';
import { Route, Routes, useHref } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import s from './app.module.scss';
import { Footer } from '../common/footer/footer';
import { PageGridWrp } from '../common/pageGridWrp/pageGridWrp';
import { RightPanel } from '../common/rightPanel/rightPanel';
import { MainPage } from '../pages/mainPage/mainPage';
import { OfficePage } from '../pages/officePage/officePage';
import { DevelopmentPage } from '../pages/servicesPages/developmentPage/developmentPage';
import { OutsourcingPage } from '../pages/servicesPages/outsourcingPage/outsourcingPage';
import { LegalInfoPage } from '../pages/legalInfoPage/legalInfoPage';
import { ZennolabPage } from '../pages/zennolabPage/zennolabPage';
import { CookiesSection } from '../common/cookiesSection/cookiesSection';
import { VerifyPage } from '../pages/verifyPage/verifyPage';
import { getUserData } from '../../store/features/user/thunks';
import { IsHostingPage } from '../pages/isHostingPage/isHostingPage';

export const WindowWidthContext = createContext();

export const App = () => {
	const dispatch = useDispatch();
	const href = useHref();

	const isUserAuth = document.cookie
		.split('; ')
		.find((row) => row.startsWith('userAuth='));

	const userToken = isUserAuth?.split('=')[1];

	if (userToken !== undefined) {
		// const userToken = isUserAuth.split('=')[1];

		dispatch(getUserData(userToken));
		// console.log(userToken);
	}

	const windowWidthContextValue = {
		windowWidth: window.innerWidth,
		windowWidthTablet: 900,
		windowWidthMobile: 500,
	};

	return (
		<WindowWidthContext.Provider value={windowWidthContextValue}>
			<div className={s.app}>
				{/* {href !== '/isHosting' && ( */}
				<PageGridWrp>
					<main>
						<Routes>
							<Route index element={<MainPage />} />

							<Route path='/office/*' element={<OfficePage />} />
							{/* <Route path='office/history' element={<div>www</div>} /> */}

							<Route path='/zennolab' element={<ZennolabPage />} />

							<Route path='/development' element={<DevelopmentPage />} />

							<Route path='/outsourcing' element={<OutsourcingPage />} />

							<Route path='/legalInfo' element={<LegalInfoPage />} />
							<Route path='verify/:verifyToken' element={<VerifyPage />} />
						</Routes>
					</main>

					<Footer />

					<RightPanel />

					<CookiesSection token={userToken} />
				</PageGridWrp>
				{/* )} */}

				{/* {href === '/isHosting' && (
					<Routes>
						<Route path='isHosting' element={<IsHostingPage />} />
					</Routes>
				)} */}
			</div>
		</WindowWidthContext.Provider>
	);
};
