import { createAsyncThunk } from '@reduxjs/toolkit';
import { productsApi } from '../../../core/api/api';
import { setProductList } from './productsSlice';

export const getProductList = createAsyncThunk(
	'product/getProductList',
	async (_, { rejectWithValue, dispatch }) => {
		productsApi
			.getProductList()
			.then((res) => {
				// console.log(res);
				dispatch(setProductList(res.data));
			})
			.catch((err) => console.log(err));
	}
);
