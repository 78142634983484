import { createAsyncThunk } from '@reduxjs/toolkit';
import { cartApi } from '../../../core/api/api';
import { setCart } from './cart';

export const getCart = createAsyncThunk(
	'cart/getCart',
	async (userId, { dispatch }) => {
		cartApi
			.getCart(userId)
			.then((res) => {
				// console.log(res);
				dispatch(setCart(res.data));
			})
			.catch((err) => {
				console.log(err);
			});
	}
);

export const updateCart = createAsyncThunk(
	'cart/updateCart',
	async ({ userId, newCart }, { dispatch }) => {
		cartApi
			.updateCart({ userId, newCart })
			.then((res) => {
				// console.log(res);
				dispatch(setCart(res.data));
			})
			.catch((err) => {
				console.log(err);
			});
	}
);
