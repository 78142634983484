import s from './buttonArrow.module.scss';
import { ReactComponent as LinkArrow } from '../../../images/icons/arrow.svg';

export const ButtonArrow = ({ classes = null, clickFun = () => {} }) => {
	return (
		<button className={`${s.btn} ${classes}`} onClick={clickFun}>
			<LinkArrow />
		</button>
	);
};
