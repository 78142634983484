import s from './userInfo.module.scss';
import { ReactComponent as EditIcon } from '../../../../images/icons/editIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../../store/selectors';
import { Input } from '../../../common/input/input';
import { useState } from 'react';
import { Button } from '../../../common/button/button';
import {
	updateUserData,
	verifyEmail,
} from '../../../../store/features/user/thunks';

export const UserInfo = () => {
	const dispatch = useDispatch();

	const {
		id,
		firstName,
		lastName,
		middleName,
		email,
		phone,
		isVerifyEmail,
		isVerifyPhone,
	} = useSelector(selectUser);

	const [userFirstName, setUserFirstName] = useState(firstName);
	const [userLastName, setUserLastName] = useState(lastName);
	const [userMiddleName, setUserMiddleName] = useState(
		middleName === null ? '' : middleName
	);
	const [userEmail, setUserEmail] = useState(email);
	const [userPhone, setUserPhone] = useState(phone);
	const [userPassword, setUserPassword] = useState('');
	const [copyUserPassword, setCopyUserPassword] = useState('');
	const [isEdit, setIsEdit] = useState(false);
	const [error, setError] = useState('');

	const [balance, setBalance] = useState('');

	const handlerSetBalance = (e) => {
		setBalance(e.target.value);
	};

	const handlerVerifyEmail = () => {
		dispatch(
			verifyEmail({
				email,
			})
		);
	};

	const handlerUpdateUserInfo = () => {
		setError('');

		if (
			firstName === userFirstName &&
			lastName === userLastName &&
			middleName === userMiddleName &&
			email === userEmail &&
			phone === userPhone &&
			userPassword === '' &&
			copyUserPassword === ''
		) {
			setIsEdit(false);
			return;
		}

		if (userPassword !== copyUserPassword) {
			setError('Пароли не совпадают!');
			return;
		}
		dispatch(
			updateUserData({
				id,
				firstName: userFirstName,
				lastName: userLastName,
				middleName: userMiddleName,
				email: userEmail,
				phone: userPhone,
				password: userPassword,
				isVerifyEmail,
				isVerifyPhone,
				setIsEdit,
				setError,
			})
		);
	};

	return (
		<section className={s.userInfo}>
			<div>
				<h3 className={`sectionTitle`}>Личная информация</h3>
				<p>Внутренний курс: 1 $ - 98 ₽</p>
			</div>

			<div className={s.userInfo_content}>
				<form className={s.userInfo_content_info}>
					<label>
						Фамилия:
						<Input
							inputValue={userLastName}
							changeFunction={(e) => setUserLastName(e.target.value)}
							disabled={!isEdit}
						/>
					</label>
					<label>
						Имя:
						<Input
							inputValue={userFirstName}
							changeFunction={(e) => setUserFirstName(e.target.value)}
							disabled={!isEdit}
						/>
					</label>
					<label>
						Отчество:
						<Input
							inputValue={userMiddleName}
							changeFunction={(e) => setUserMiddleName(e.target.value)}
							disabled={!isEdit}
						/>
					</label>
					<label>
						Почта:
						<Input
							type={'text'}
							inputValue={userEmail}
							changeFunction={(e) => setUserEmail(e.target.value)}
							disabled={!isEdit}
						/>
					</label>
					{!isVerifyEmail && (
						<span>
							Требуется{' '}
							<button type='button' onClick={handlerVerifyEmail}>
								подтвердить e-mail
							</button>
						</span>
					)}

					<label>
						Номер телефона:
						<Input
							inputValue={userPhone}
							changeFunction={(e) => setUserPhone(e.target.value)}
							disabled={!isEdit}
						/>
					</label>
					{/* {!isVerifyPhone && <span>Требуется подтвердить телефон</span>} */}

					{!isEdit ? (
						<p>Пароль: ************</p>
					) : (
						<>
							<label>
								Новый пароль:
								<Input
									inputValue={userPassword}
									changeFunction={(e) => setUserPassword(e.target.value)}
									disabled={!isEdit}
								/>
							</label>

							<label>
								Повторите новый пароль:
								<Input
									inputValue={copyUserPassword}
									changeFunction={(e) => setCopyUserPassword(e.target.value)}
									disabled={!isEdit}
								/>
							</label>

							{error && (
								<p className={s.userInfo_content_info_error}>{error}</p>
							)}
							{/* {isEdit && ( */}
							<Button
								type='button'
								text='Обновить'
								clickFunction={handlerUpdateUserInfo}
							/>
							{/* )} */}
						</>
					)}
				</form>

				{!isEdit ? (
					<button
						className={s.userInfo_content_edit}
						// onClick={() => setIsEdit(!isEdit)}
					>
						<>
							<EditIcon /> <p>Редактировать</p>
						</>
					</button>
				) : (
					<button
						className={s.userInfo_content_edit_cross}
						onClick={() => setIsEdit(!isEdit)}></button>
				)}

				{!isEdit && (
					<div className={s.userInfo_content_card}>
						<div>
							<span>Ваш баланс</span>
							<p>1280 ₽</p>
						</div>

						<Input
							type={'number'}
							inputValue={balance}
							changeFunction={handlerSetBalance}
							placeholder={'Введите сумму'}
						/>

						<Button text={'Пополнить'} />
					</div>
				)}
			</div>
		</section>
	);
};
