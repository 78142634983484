import { useContext } from 'react';
import { WindowWidthContext } from '../../../app/app';
import { Button } from '../../../common/button/button';
import s from './historySection.module.scss';

export const HistorySection = () => {
	const { windowWidth, windowWidthMobile } = useContext(WindowWidthContext);

	const replenishmentList = [
		{ sum: 5_111_234, date: '12.12.2011 13:32' },
		{ sum: 5_111_234, date: '12.12.2011 13:32' },
		{ sum: 5_111_234, date: '12.12.2011 13:32' },
		{ sum: 5_111_234, date: '12.12.2011 13:32' },
		{ sum: 5_111_234, date: '12.12.2011 13:32' },
	];

	const purchaseList = [
		{
			publisher: 'Zennolab',
			product: 'ZennoPoster',
			service: 'Покупка + поддержка на год',
			price: 5489,
			date: '12.08.2024 13:51',
		},
		{
			publisher: 'Zennolab',
			product: 'ZennoPoster',
			service: 'Покупка + поддержка на год',
			price: 5489,
			date: '12.08.2024 13:51',
		},
		{
			publisher: 'Zennolab',
			product: 'ZennoPoster',
			service: 'Покупка + поддержка на год',
			price: 5489,
			date: '12.08.2024 13:51',
		},
		{
			publisher: 'Zennolab',
			product: 'ZennoPoster',
			service: 'Покупка + поддержка на год',
			price: 5489,
			date: '12.08.2024 13:51',
		},
	];

	const handlerList = (list, quantityEl) => {
		if (windowWidth > windowWidthMobile) return list;
		return list.slice(0, quantityEl);
	};

	return (
		<section className={s.historySection}>
			<HistoryBlock
				title={'История пополнений'}
				componentList={handlerList(replenishmentList, 5).map((el, i) => (
					<ReplenishmentCard key={i} sum={el.sum} date={el.date} />
				))}
				// btnClick={}
			/>

			<HistoryBlock
				title={'История покупок'}
				componentList={handlerList(purchaseList, 2).map((el, i) => (
					<PurchaseCard
						key={i}
						publisher={el.publisher}
						product={el.product}
						service={el.service}
						price={el.price}
						date={el.date}
					/>
				))}
				// btnClick={}
			/>
		</section>
	);
};

function HistoryBlock({ title, componentList, btnClick }) {
	return (
		<div className={s.historyBlock}>
			<h3>{title}</h3>

			<div>{componentList}</div>

			<Button text={'Посмотреть все'} clickFunction={btnClick} />
		</div>
	);
}

function ReplenishmentCard({ sum, date }) {
	return (
		<div className={s.replenishmentCard}>
			<p>{sum} руб.</p>

			<div>{date}</div>
		</div>
	);
}

function PurchaseCard({ publisher, product, service, price, date }) {
	return (
		<div className={s.purchaseCard}>
			<p>
				<span>Издатель:</span> {publisher}
			</p>
			<p>
				<span>Продукт:</span> {product}
			</p>
			<p>
				<span>Услуга:</span> {service}
			</p>
			<p>
				<span>Стоимость:</span> {price} руб/месяц
			</p>
			<div>{date}</div>
		</div>
	);
}
