import { createAsyncThunk } from '@reduxjs/toolkit';
import { authApi, userApi, verifyApi } from '../../../core/api/api';
import { setIsVerifyPhone, setUser } from './userSlice';
import { createCookie } from '../../../core/utils/functions/cookie/createCookie';

export const userRegistration = createAsyncThunk(
	'user/userRegistration',
	async (
		{
			firstName,
			lastName,
			middleName,
			email,
			phone,
			password,
			setIsUserDataCorrect,
			setError,
		},
		{ dispatch }
	) => {
		userApi
			.register({
				firstName,
				lastName,
				middleName,
				email,
				phone,
				password,
			})
			.then((res) => {
				// console.log(res);
				setIsUserDataCorrect(true);
				dispatch(setUser(res.data));
				// dispatch();
			})
			.catch((err) => {
				console.log('err', err);
				setError(err.response.data.error);
			});
	}
);

export const getUserData = createAsyncThunk(
	'user/getUserData',
	async (userToken, { rejectWithValue, dispatch }) => {
		authApi
			.getUserData(userToken)
			.then((res) => {
				// console.log(res);
				dispatch(setUser(res.data));
			})
			.catch((err) => {
				console.log('err', err);
			});
	}
);

export const updateUserData = createAsyncThunk(
	'user/updateUserData',
	async (data, { dispatch }) => {
		userApi
			.updateData({
				id: data.id,
				firstName: data.firstName,
				lastName: data.lastName,
				middleName: data.middleName,
				email: data.email,
				phone: data.phone,
				password: data.password,
				// isVerifyEmail: data.isVerifyEmail,
				// isVerifyPhone: data.isVerifyPhone,
			})
			.then((res) => {
				console.log(res);
				dispatch(setUser());
			})
			.catch((err) => {
				console.log(err);
			});
	}
);

export const authByEmail = createAsyncThunk(
	'user/authByEmail',
	async ({ email, password, isRemember, setError }, { dispatch }) => {
		// console.log(email);
		authApi
			.emailAuth(email, password)
			.then((res) => {
				// console.log(res.data);
				// if (!res.data.user.isVerifyEmail) {
				// 	setError('Электронная почта не подтверждена.');
				// 	return;
				// }

				if (isRemember) {
					// console.log(res.data.token);
					const cookieTimeLive = 60 * 60 * 24 * 7; // сек * мин * час * дн
					createCookie('userAuth', res.data.token, cookieTimeLive);
					// document.cookie = `userAuth = ${res.data.token}; max-age = ${cookieTimeLive}`;
				}

				dispatch(setUser(res.data.user));
			})
			.catch((err) => {
				console.log(err);
				setError(err.response.data.error);
			});
	}
);

export const authByPhone = createAsyncThunk(
	'user/authByPhone',
	async ({ phone, smsCode, isRemember, setError }, { dispatch }) => {
		authApi
			.phoneAuth(phone, smsCode)
			.then((res) => {
				// console.log(res.data);
				// if (!res.data.user.isVerifyPhone) {
				// 	setError('Аккаунт не подтвержден.');
				// 	return;
				// }

				if (isRemember) {
					// console.log(res.data.token);
					const cookieTimeLive = 60 * 60 * 24 * 7;
					createCookie('userAuth', res.data.token, cookieTimeLive);
					// document.cookie = `userAuth = ${res.data.token}; max-age = ${cookieTimeLive}`;
				}

				dispatch(setUser(res.data.user));
			})
			.catch((err) => {
				console.log(err);
				setError(err.response.data.error);
			});
	}
);

export const getSmsCode = createAsyncThunk(
	'user/getSmsCode',
	async ({ phone, setIsPhoneCorrect, setError }) => {
		setError('');

		authApi
			.sendSms(phone)
			.then((res) => {
				// console.log(res);

				if (res.status === 200) {
					setIsPhoneCorrect(true);
				}
			})
			.catch((err) => {
				console.log('err', err);
				setError(err.response.data.error);
			});
	}
);

export const verifySmsCode = createAsyncThunk(
	'user/verifySmsCode',
	async ({ phone, smsCode, setError }, { dispatch }) => {
		setError('');

		verifyApi
			.verifySms({ phone, sms: smsCode })
			.then((res) => {
				// console.log(res);

				if (res.status === 200) {
					dispatch(setIsVerifyPhone(res.data.isVerifyPhone));
				}
			})
			.catch((err) => {
				console.log('err', err);
				setError(err.response.data.error);
			});
	}
);

export const verifyEmail = createAsyncThunk(
	'user/verifyEmail',
	async ({ email, setError = () => {} }, { dispatch }) => {
		setError('');

		verifyApi
			.resendVerify(email)
			.then((res) => {
				console.log(res);

				if (res.status === 200) {
					// dispatch(setIsVerifyPhone(res.data.isVerifyPhone));
				}
			})
			.catch((err) => {
				console.log('err', err);
				setError(err.response.data.error);
			});
	}
);
