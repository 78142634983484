import s from './titleSection.module.scss';
import { ReactComponent as Im } from '../../../images/logo/im.svg';
import { useNavigate } from 'react-router-dom';

export const TitleSection = ({
	classes,
	title,
	subtitle,
	text,
	img = <></>,
}) => {
	const navigate = useNavigate();

	return (
		<section className={`${s.titleSection} ${classes}`}>
			<div className={s.titleSection_content}>
				<div className={s.titleSection_content_imgWrp}>
					<Im onClick={() => navigate('/')} />

					{img}
				</div>

				<div className={s.titleSection_content_titleWrp}>
					<h2 className='pageTitle'>{title}</h2>
					<p>{subtitle}</p>
				</div>
			</div>

			<p>{text}</p>
		</section>
	);
};
