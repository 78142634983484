import s from './legalInfoPage.module.scss';
import offerSalesImg from '../../../images/pictures/doc1.png';
import privacyPolicyImg from '../../../images/pictures/doc2.png';
import licenseAgreementImg from '../../../images/pictures/doc3.png';
import paymentTermsImg from '../../../images/pictures/doc4.png';
import licenseAgreement from '../../../documents/Лицензионное_соглашение.pdf';
import offerSales from '../../../documents/Оферта_продажи_программных_продуктов.pdf';
import privacyPolicy from '../../../documents/Политика_конфиденциальности.pdf';
import paymentTerms from '../../../documents/Условия_оплаты.pdf';
import { ReactComponent as IconPdf } from '../../../images/icons/pdf.svg';
import { FeedbackSection } from '../../common/feedbackSection/feedbackSection';

export const LegalInfoPage = () => {
	return (
		<div className='pageContainer'>
			<section className={s.legalInfo}>
				<h2 className='pageTitle'>Правовая информация</h2>

				<div className={s.legalInfo_docList}>
					<div className={s.legalInfo_docList_docBlock}>
						<img src={licenseAgreementImg} alt='doc' />

						<a href={licenseAgreement} target='_blank'>
							<IconPdf /> Лицензионное соглашение
						</a>
					</div>

					<div className={s.legalInfo_docList_docBlock}>
						<img src={offerSalesImg} alt='doc' />

						<a href={offerSales} target='_blank'>
							<IconPdf /> Оферта продажи программных продуктов
						</a>
					</div>

					<div className={s.legalInfo_docList_docBlock}>
						<img src={privacyPolicyImg} alt='doc' />

						<a href={privacyPolicy} target='_blank'>
							<IconPdf /> Политика конфиденциальности
						</a>
					</div>

					<div className={s.legalInfo_docList_docBlock}>
						<img src={paymentTermsImg} alt='doc' />

						<a href={paymentTerms} target='_blank'>
							<IconPdf /> Условия оплаты
						</a>
					</div>
				</div>
			</section>

			<section className={s.contacts}>
				<h3 className='sectionTitle'>Контакты</h3>

				<p>ООО "ИНФОРМАЦИОННАЯ МЕХАНИКА"</p>

				<div>
					<p>
						603070, Нижегородская область, г Нижний Новгород, Мещерский б-р, д.
						3/3 оф.109
					</p>
					<p>ИНН: 5257201999</p>
					<p>Телефон: +7 (930) 665 65-76</p>
					<p>ОГРН: 1215200009606</p>
					<p>Служба поддержки: support@zennolab.com</p>
				</div>
			</section>

			<FeedbackSection />
		</div>
	);
};
