import s from './outsourcingPage.module.scss';
import { TitleSection } from '../../../common/titleSection/titleSection';
import { ServicesSection } from '../servicesSection/servicesSection';
import { OperatingProcedureSection } from '../operatingProcedureSection/operatingProcedureSection';
import { YouWillGetSection } from '../youWillGetSection/youWillGetSection';
import { FeedbackSection } from '../../../common/feedbackSection/feedbackSection';

export const OutsourcingPage = () => {
	const serviceList = [
		{
			name: 'Обслуживание серверов',
			description:
				'Поддержка, удаленное администрирование и мониторинг серверов. Устранение причин сбоя, снижение времени простоя',
		},
		{
			name: 'Виртуальные сервера и приложения',
			description:
				'Внедрение и администрирование систем виртуализации. Увеличение гибкости и эффективности использования ресурсов',
		},
		{
			name: 'Администрирование баз данных',
			description:
				'Внедрение, оптимизация и обеспечение безопасности баз данных',
		},
		{
			name: 'Кластеризация серверов',
			description:
				'Объединение нескольких серверов в единую группу для обеспечения надёжности и производительности',
		},
		{
			name: 'Резервное копирование данных',
			description:
				'Обеспечение резервного копирования и восстановление информации при необходимости',
		},
		{
			name: 'Управление доступами',
			description:
				'Создание, разграничение доступов, модификация учётных записей и групп пользователей',
		},
		{
			name: 'Настройка удаленного доступа',
			description:
				'Организация совместной удаленной работы через защищенное VPN соединение',
		},
		{
			name: 'Корпоративные данные',
			description:
				'Защита от потери информации после аварий, непреднамеренных и преднамеренных действий пользователей',
		},
	];

	return (
		<div className='pageContainer'>
			<TitleSection
				classes={s.titleSectionBgr}
				title={'Услуги IT-Аутсорсинга'}
				subtitle={
					'Возьмем под полный контроль вашу IT-инфраструкруру снизим затраты и обеспечим полную безопасность'
				}
				text={
					'Компания «Информационная механика» предлагает широкий спектр продуктов и услуг в области информационных технологий. Мы специализируемся на разработке и поддержке программного обеспечения, создании сайтов и предоставлении технической поддержки.'
				}
			/>

			<ServicesSection serviceClasses={s.service} serviceList={serviceList} />

			<OperatingProcedureSection procedureClasses={s.procedure} />

			<YouWillGetSection cardClasses={s.card} />

			<FeedbackSection />
		</div>
	);
};
