import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import s from './login.module.scss';
import { Input } from '../input/input';
import { FormCard } from '../formCard/formCard';
import { ModalWindow } from '../modalWindow/modalWindow';
import { LoginCard } from './loginCard/loginCard';
import { formatInputPhoneNumber } from '../../../core/utils/functions/formatPhoneNumber/formatInputPhoneNumber.js';
import { RegisterCard } from './registerCard/registerCard';
import { InputPassword } from '../input/inputPassword';
import {
	authByEmail,
	authByPhone,
	getSmsCode,
	userRegistration,
	verifyEmail,
	verifySmsCode,
} from '../../../store/features/user/thunks.js';
import { selectUser } from '../../../store/selectors';
import { Checkbox } from '../checkbox/checkbox';
import { formatPhoneNumberForDataBase } from '../../../core/utils/functions/formatPhoneNumber/formatPhoneNumberForDataBase.js';
import { userApi } from '../../../core/api/api.js';

export const Login = ({ closeWindow }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userData = useSelector(selectUser);

	const USER_EMAIL_IS_NOT_VERIFY = 'Пользователь не подтвердил email';
	const EMAIL_LOGIN_TYPE = 'EMAIL_LOGIN_TYPE';
	const PHONE_LOGIN_TYPE = 'PHONE_LOGIN_TYPE';

	const [isRegister, setIsRegister] = useState(false);
	const [isLogin, setIsLogin] = useState(true);
	const [loginType, setLoginType] = useState(EMAIL_LOGIN_TYPE);
	const [isForgotPassword, setIsForgotPassword] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [middleName, setMiddleName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState('');
	const [repeatPassword, setRepeatPassword] = useState('');
	const [smsCode, setSmsCode] = useState('');

	// const [isVerify, setIsVerify] = useState(true);
	// const [resultVerify, setResultVerify] = useState('');
	const [isRemember, setIsRemember] = useState(false);
	const [isPhoneCorrect, setIsPhoneCorrect] = useState(false);
	const [isUserDataCorrect, setIsUserDataCorrect] = useState(false);
	const [error, setError] = useState('');

	const openRegister = () => {
		setIsRegister(true);
		setIsLogin(false);
		setError('');
	};

	const handlerBackBtn = () => {
		setIsLogin(true);
		setIsRegister(false);
		setIsForgotPassword(false);

		setFirstName('');
		setLastName('');
		setMiddleName('');
		setEmail('');
		setPhone('');
		setPassword('');
		setRepeatPassword('');
		setSmsCode('');
	};
	const handlerFirstName = (e) => setFirstName(e.target.value);
	const handlerLastName = (e) => setLastName(e.target.value);
	const handlerMiddleName = (e) => setMiddleName(e.target.value);
	const handlerEmail = (e) => setEmail(e.target.value);
	const handlerPhone = (e) => {
		const formattedPhoneNumber = formatInputPhoneNumber(e.target.value);
		// setPhone(e.target.value);
		setPhone(formattedPhoneNumber);
	};
	const handlerPassword = (e) => setPassword(e.target.value);
	const handlerRepeatPassword = (e) => setRepeatPassword(e.target.value);
	const handlerSmsCode = (e) => setSmsCode(e.target.value);

	const handlerRegister = () => {
		if (password !== repeatPassword) {
			// console.log('repeatPassword false');
			setError('Пароли не совпадают!');
			return;
		}

		setError('');

		const phoneForDataBase = formatPhoneNumberForDataBase(phone);
		// setIsUserDataCorrect(true);

		dispatch(
			userRegistration({
				firstName,
				lastName,
				middleName,
				email,
				phone: phoneForDataBase,
				password,
				setIsUserDataCorrect,
				setError,
			})
		);
	};

	const handlerLogin = () => {
		// e.preventDefault();
		setError('');

		if (loginType === PHONE_LOGIN_TYPE && isPhoneCorrect) {
			const phoneForDataBase = formatPhoneNumberForDataBase(phone);

			dispatch(
				authByPhone({ phone: phoneForDataBase, smsCode, isRemember, setError })
			);
			return;
		}

		dispatch(authByEmail({ email, password, isRemember, setError }));
	};

	const handlerGetSmsCode = () => {
		const phoneForDataBase = formatPhoneNumberForDataBase(phone);

		dispatch(
			getSmsCode({ phone: phoneForDataBase, setIsPhoneCorrect, setError })
		);
	};

	const handlerVerifySmsCode = () => {
		const phoneForDataBase = formatPhoneNumberForDataBase(phone);

		dispatch(
			verifySmsCode({
				phone: phoneForDataBase,
				smsCode,
				setIsPhoneCorrect,
				setError,
			})
		);
	};

	const handlerVerifyEmail = () => {
		dispatch(
			verifyEmail({
				email,
				setError,
			})
		);
	};

	const handlerForgotPassword = () => {
		setError('');

		userApi
			.resetPassword(email)
			.then((res) => {
				setSuccessMessage(res.data.message);
			})
			.catch((err) => {
				setError(err.response.data.error);
			});
	};

	useEffect(() => {
		if (userData.isVerifyPhone || userData.isVerifyEmail) {
			navigate('office');
			closeWindow();
		}
	}, [userData.isVerifyPhone, userData.isVerifyEmail]);

	return (
		<ModalWindow clickFunction={closeWindow}>
			<>
				{isRegister && (
					<FormCard
						classes={s.form}
						submitFunction={
							isUserDataCorrect ? handlerVerifySmsCode : handlerRegister
						}
						content={
							<>
								{isUserDataCorrect ? (
									<LoginCard
										navigateText={'Вернуться'}
										isBtnBack={true}
										navigateClickFn={() => {
											handlerBackBtn();
											setIsUserDataCorrect(false);
										}}
										title={'Введите код из смс'}
										inputBlockContent={
											<Input
												type={'tel'}
												inputValue={smsCode}
												changeFunction={handlerSmsCode}
												placeholder='----'
												maxLength={4}
												required={true}
											/>
										}
										rememberBlockContent={
											<div
												onClick={() =>
													dispatch(
														getSmsCode({ phone, setIsPhoneCorrect, setError })
													)
												}>
												Отправить код повторно
											</div>
										}
										btnText={'Войти'}
										isLoginOptionsBlock={false}
										error={error}
									/>
								) : (
									<RegisterCard
										backBtnFn={() => {
											handlerBackBtn();
											setIsRegister(false);
										}}
										firstName={firstName}
										lastName={lastName}
										middleName={middleName}
										email={email}
										phone={phone}
										password={password}
										repeatPassword={repeatPassword}
										handlerFirstName={handlerFirstName}
										handlerLastName={handlerLastName}
										handlerMiddleName={handlerMiddleName}
										handlerEmail={handlerEmail}
										handlerPhone={handlerPhone}
										handlerPassword={handlerPassword}
										handlerRepeatPassword={handlerRepeatPassword}
										error={error}
									/>
								)}
							</>
						}
					/>
				)}

				{isLogin && loginType === EMAIL_LOGIN_TYPE && (
					<FormCard
						classes={s.form}
						submitFunction={
							error === USER_EMAIL_IS_NOT_VERIFY
								? handlerVerifyEmail
								: handlerLogin
						}
						content={
							<LoginCard
								navigateText={'Войти по телефону'}
								navigateClickFn={() => {
									handlerBackBtn();
									setLoginType(PHONE_LOGIN_TYPE);
									setError('');
								}}
								title={'Вход по e-mail'}
								inputBlockContent={
									<>
										<Input
											type={'email'}
											inputValue={email}
											changeFunction={handlerEmail}
											placeholder='Почта'
											required={true}
										/>

										<InputPassword
											inputValue={password}
											changeFunction={handlerPassword}
											placeholder='Пароль'
											required={true}
										/>
									</>
								}
								rememberBlockContent={
									<>
										<Checkbox
											text={'Запомнить'}
											isChecked={isRemember}
											changeFunction={() => setIsRemember(!isRemember)}
										/>

										<div
											onClick={() => {
												setIsForgotPassword(true);
												setIsLogin(false);
											}}>
											Забыли пароль?
										</div>
									</>
								}
								btnText={
									error === USER_EMAIL_IS_NOT_VERIFY
										? 'Отправить подтверждение на почту'
										: 'Войти'
								}
								openRegisterFn={openRegister}
								error={error}
							/>
						}
					/>
				)}

				{isLogin && loginType === PHONE_LOGIN_TYPE && (
					<FormCard
						classes={s.form}
						submitFunction={isPhoneCorrect ? handlerLogin : handlerGetSmsCode}
						content={
							<>
								{isPhoneCorrect ? (
									<LoginCard
										navigateText={'Вернуться'}
										isBtnBack={true}
										navigateClickFn={() => {
											handlerBackBtn();
											setIsPhoneCorrect(false);
										}}
										title={'Введите код из смс'}
										inputBlockContent={
											<Input
												type={'tel'}
												inputValue={smsCode}
												changeFunction={handlerSmsCode}
												placeholder='----'
												maxLength={4}
												required={true}
											/>
										}
										rememberBlockContent={
											<div
												onClick={() =>
													dispatch(
														getSmsCode({ phone, setIsPhoneCorrect, setError })
													)
												}>
												Отправить код повторно
											</div>
										}
										btnText={'Войти'}
										isLoginOptionsBlock={false}
										error={error}
									/>
								) : (
									<LoginCard
										navigateText={'Войти по e-mail'}
										navigateClickFn={() => {
											handlerBackBtn();
											setLoginType(EMAIL_LOGIN_TYPE);
											setError('');
										}}
										title={'Вход по телефону'}
										inputBlockContent={
											<Input
												type={'tel'}
												inputValue={phone}
												changeFunction={handlerPhone}
												placeholder='+7 (ХХХ) ХХХ ХХ-ХХ'
												maxLength={18}
												required={true}
											/>
										}
										rememberBlockContent={
											<Checkbox
												text={'Запомнить'}
												isChecked={isRemember}
												changeFunction={() => setIsRemember(!isRemember)}
											/>
										}
										btnText={'Получить код'}
										openRegisterFn={openRegister}
										error={error}
									/>
								)}
							</>
						}
					/>
				)}

				{isForgotPassword && (
					<FormCard
						classes={s.form}
						submitFunction={
							error === USER_EMAIL_IS_NOT_VERIFY
								? handlerVerifyEmail
								: handlerForgotPassword
						}
						content={
							successMessage ? (
								<h3>{successMessage}</h3>
							) : (
								<LoginCard
									navigateText={'Войти по email'}
									navigateClickFn={() => {
										handlerBackBtn();
										setError('');
									}}
									title={'Забыли пароль?'}
									inputBlockContent={
										<>
											<Input
												type={'email'}
												inputValue={email}
												changeFunction={handlerEmail}
												placeholder='Почта'
												required={true}
											/>
										</>
									}
									btnText={'Отправить пароль на почту'}
									isLoginOptionsBlock={false}
									error={error}
								/>
							)
						}
					/>
				)}
			</>
		</ModalWindow>
	);
};
