import { useNavigate } from 'react-router-dom';
import s from './ourProjects.module.scss';
import { ReactComponent as LinkArrow } from '../../../../images/icons/arrow.svg';
import { ReactComponent as Zennolab } from '../../../../images/logo/zennolab.svg';
import { ReactComponent as ZennoPoster } from '../../../../images/logo/zPoster.svg';
import { ReactComponent as ZennoDroid } from '../../../../images/logo/zDroid.svg';
import { ReactComponent as ProxyChecker } from '../../../../images/logo/proxyChecker.svg';
import { ReactComponent as Clock } from '../../../../images/icons/clock.svg';
import { ReactComponent as Read } from '../../../../images/icons/read.svg';
import { ReactComponent as World } from '../../../../images/icons/world.svg';
import { ReactComponent as Shield } from '../../../../images/icons/shield.svg';
import { ButtonArrow } from '../../../common/buttonArrow/buttonArrow';

export const OurProjects = () => {
	const navigate = useNavigate();

	return (
		<section className={s.ourProjects}>
			<h3 className='sectionTitle'>Наши проекты</h3>

			<div className={s.ourProjects_projects}>
				<div className={s.ourProjects_projects_project}>
					<div className={s.ourProjects_projects_project_titleBlock}>
						<Zennolab />

						<ButtonArrow clickFun={() => navigate('zennolab')} />
					</div>

					<div className={s.ourProjects_projects_project_serviceList}>
						<p>
							Решения для автоматизации деятельности веб-мастеров и
							SEO-специалистов
						</p>

						<div className={s.ourProjects_projects_project_serviceList_service}>
							<ZennoPoster />

							<p>Может повторить всё, что вы делаете в браузере вручную.</p>
						</div>

						<div className={s.ourProjects_projects_project_serviceList_service}>
							<ZennoDroid />

							<p>Автоматизация Android-приложений.</p>
						</div>

						<div className={s.ourProjects_projects_project_serviceList_service}>
							<ProxyChecker />

							<p>
								Программа для регулярной проверки прокси по множеству параметров
								и экспорта в удобном формате.
							</p>
						</div>
					</div>
				</div>

				<div className={s.ourProjects_projects_project}>
					<div className={s.ourProjects_projects_project_titleBlock}>
						<div className={s.ourProjects_projects_project_titleBlock_logo}>
							<p>
								<span>A</span>daptive <span>S</span>earch
							</p>
						</div>

						<p>Нечеткий поиск с чёткими преимуществами</p>

						<a
							href='https://adaptivesearch.ru/'
							className='linkArrow'
							target='_blank'>
							<ButtonArrow />
						</a>
					</div>

					<p className={s.ourProjects_projects_project_info}>
						AdaptiveSearch – это система умного поиска, которая выявляет
						совпадения между символами в поисковом запросе и Вашей базой данных
						или таблицей.
					</p>

					<div className={s.ourProjects_projects_project_advantages}>
						<p>Ускоряет работу сотрудников</p>
						<p>Увеличивает прибыль</p>
						<p>Упрощает поиск товара</p>
					</div>
				</div>

				<div className={s.ourProjects_projects_project}>
					<div className={s.ourProjects_projects_project_titleBlock}>
						<div className={s.ourProjects_projects_project_titleBlock_logo}>
							<span>1</span>юридический.рф
						</div>

						<p>Онлайн сервис для подачи документов в суды и иные гос. органы</p>

						<a
							href='http://justicia.ltd/'
							className='linkArrow'
							target='_blank'>
							<ButtonArrow />
						</a>
					</div>

					<div className={s.ourProjects_projects_project_cardList}>
						<div className={s.ourProjects_projects_project_cardList_card}>
							<div
								className={
									s.ourProjects_projects_project_cardList_card_circleWithShadow
								}>
								<Clock />
							</div>

							<h4
								className={s.ourProjects_projects_project_cardList_card_title}>
								Экономия времени
							</h4>

							<p className={s.ourProjects_projects_project_cardList_card_text}>
								Онлайн услуга занимает гораздо меньше времени, чем посещение
								государственных органов или юриста лично.
							</p>
						</div>

						<div className={s.ourProjects_projects_project_cardList_card}>
							<div
								className={
									s.ourProjects_projects_project_cardList_card_circleWithShadow
								}>
								<Read />
							</div>

							<h4
								className={s.ourProjects_projects_project_cardList_card_title}>
								Широкий спектр услуг
							</h4>
							<p className={s.ourProjects_projects_project_cardList_card_text}>
								У нас можно получить не только готовый документ и отправить его
								в суд, но и получить консультанцию юриста.
							</p>
						</div>

						<div className={s.ourProjects_projects_project_cardList_card}>
							<div
								className={
									s.ourProjects_projects_project_cardList_card_circleWithShadow
								}>
								<World />
							</div>

							<h4
								className={s.ourProjects_projects_project_cardList_card_title}>
								Удобство
							</h4>
							<p className={s.ourProjects_projects_project_cardList_card_text}>
								Все операции можно выполнить из дома, офиса или даже в дороге,
								без необходимости куда-то ехать.
							</p>
						</div>

						<div className={s.ourProjects_projects_project_cardList_card}>
							<div
								className={
									s.ourProjects_projects_project_cardList_card_circleWithShadow
								}>
								<Shield />
							</div>

							<h4
								className={s.ourProjects_projects_project_cardList_card_title}>
								Безопасность
							</h4>
							<p className={s.ourProjects_projects_project_cardList_card_text}>
								Все данные передаются по защищенному каналу, что гарантирует их
								сохранность и конфиденциальность.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
