import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	productList: [],
};

export const productsSlice = createSlice({
	name: 'products',
	initialState,

	reducers: {
		setProductList: (state, action) => {
			state.productList = action.payload
				? action.payload
				: initialState.productList;
		},
	},

	// extraReducers: {
	// },
});

export const { setProductList } = productsSlice.actions;
export const productsReducer = productsSlice.reducer;
