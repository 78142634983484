import s from './feedbackSection.module.scss';

export const FeedbackSection = () => {
	return (
		<section className={s.feedback}>
			<p>
				Обращаясь к нам, вы получаете комплексное решение ваших задач в сфере
				информационных технологий, а также профессиональную техническую
				поддержку и консультации.
			</p>

			<form>
				<h4>Остались вопросы?</h4>
				<input placeholder='Ваше имя' />
				<input placeholder='Телефон' />
				<button type='submit'>Отправить</button>
			</form>
		</section>
	);
};
