import { useState } from 'react';
import s from './cookiesSection.module.scss';
import { createCookie } from '../../../core/utils/functions/cookie/createCookie';
import { getCookie } from '../../../core/utils/functions/cookie/getCookie';

export const CookiesSection = ({ token }) => {
	const [isVisible, setIsVisible] = useState(!getCookie('userAcceptCookie'));

	const handlerAcceptCookies = () => {
		if (token !== undefined) {
			const cookieTimeLive = 60 * 60 * 24 * 7; // сек * мин * час * дн
			createCookie('userAcceptCookie', token, cookieTimeLive);
		}

		setIsVisible(false);
	};

	const handlerRejectCookies = () => {
		setIsVisible(false);
	};

	return (
		<>
			{isVisible && (
				<section className={s.cookiesBlock}>
					<p>
						Мы используем файлы cookie для оценки маркетинговых усилий и
						улучшения наших услуг. Пожалуйста, просмотрите настройки файлов
						cookie и подтвердите свой выбор.
					</p>

					<div>
						<button onClick={handlerAcceptCookies}>Принять cookies</button>
						<button onClick={handlerRejectCookies}>Отклонить cookies</button>
					</div>
				</section>
			)}
		</>
	);
};
