import axios from 'axios';

const instance = axios.create({
	baseURL: 'https://infmech.ru/api/',
	credentials: true,
	'access-control-allow-credentials': true,
});

export const userApi = {
	register(userData) {
		return instance.post('register', userData);
	},

	updateData(userData) {
		return instance.post('/user/edit', userData);
	},

	resetPassword(email) {
		return instance.post('/user/resetPassword', { email });
	},
};

export const authApi = {
	getUserData(userToken) {
		return instance.get('getUserData', {
			headers: {
				Authorization: `${userToken}`,
			},
		});
	},

	emailAuth(email, password) {
		return instance.post('emailAuth', { email, password });
	},

	phoneAuth(phone, code) {
		return instance.post('phoneAuth', { phone, code });
	},

	sendSms(phone) {
		return instance.post('sendSms', { phone });
	},
};

export const verifyApi = {
	isVerify(path) {
		// console.log('instance', instance);
		// console.log('path', path);
		return instance.get(`${path}`);
	},

	verifySms(phoneData) {
		return instance.post('verifySms', phoneData);
	},

	resendVerify(email) {
		return instance.post('/resend-token', { email });
	},
};

export const publishersApi = {
	getPublisherList() {
		return instance.get('/publishers');
	},
};

export const productsApi = {
	getProductList() {
		return instance.get('/products');
	},
};

export const servicesApi = {
	getServiceListOfCurrentProduct(productId) {
		return instance.get(`/services/all/${productId}`);
	},
};

export const cartApi = {
	getCart(userId) {
		return instance.get(`cart/user/${userId}`);
	},

	updateCart({ userId, newCart }) {
		const cartBase = newCart.map((el) => ({
			service_id: el.id,
			count: el.count,
		}));

		// console.log(cartBase);
		return instance.put(`cart/user/${userId}`, { cart: cartBase });
	},
};

// export const userDocApi = {
// 	createUserDoc(userData) {
// 		return instance.post('create_document', userData);
// 	},

// 	getUserDocList(userId) {
// 		return instance.post('get_user_documents', userId);
// 	},

// 	getUserDoc(userData) {
// 		return instance.post('get_document', {
// 			signnumber: userData.href,
// 			update: userData.update,
// 		});
// 	},

// 	updateUserDoc(userData) {
// 		return instance.post('update_document', userData);
// 	},

// 	getCourt(userRegion, courtType) {
// 		// TEST!!!!!!
// 		const instance = axios.create({
// 			baseURL: 'http://95.79.30.69:9191',
// 			credentials: true,
// 			'access-control-allow-credentials': true,
// 		});

// 		return instance.get(
// 			`get-court?region=${userRegion}`
// 			// { region: userRegion, }
// 		);
// 	},

// 	getRegionList() {
// 		// TEST!!!!!!
// 		const instance = axios.create({
// 			baseURL: 'http://95.79.30.69:9191',
// 			credentials: true,
// 			'access-control-allow-credentials': true,
// 		});

// 		return instance.get(`regions`);
// 	},
// };

// export const templateApi = {
// 	getDirList() {
// 		return instance.get('folders');
// 	},

// 	getTemplateList() {
// 		return instance.get('document_templates');
// 	},

// 	saveTemplate(doc) {
// 		return instance.post('save_doc_template', doc);
// 	},

// 	createPDF(html) {
// 		return instance.post(
// 			'generateCustomPDF',
// 			{ html: html },
// 			{ responseType: 'blob' }
// 		);
// 	},
// };

// export const newsApi = {
// 	getNews() {
// 		return instance.get('get-articles');
// 	},
// };
