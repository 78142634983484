import s from './productSection.module.scss';
import { ButtonArrow } from '../../../common/buttonArrow/buttonArrow';

export const ProductSection = ({ icon, title, description, img, pathLink }) => {
	return (
		<section className={s.product}>
			<div className={s.product_head}>
				<div className={s.product_head_logo}>
					{icon}

					<h3 className='sectionTitle'>{title}</h3>
				</div>

				<span>
					Подробнее
					<a href={pathLink} target='_blank'>
						<ButtonArrow />
					</a>
				</span>
			</div>

			<img src={img} alt='img' />

			<p>{description}</p>
		</section>
	);
};
