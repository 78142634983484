import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './features/user/userSlice';
import { publishersReducer } from './features/publisher/publisherSlice';
import { productsReducer } from './features/products/productsSlice';
import { servicesReducer } from './features/services/servicesSlice';
import { cartReducer } from './features/cart/cart';

export const store = configureStore({
	reducer: {
		user: userReducer,
		publishers: publishersReducer,
		products: productsReducer,
		services: servicesReducer,
		cart: cartReducer,
	},

	// middleware: (getDefaultMiddleware) =>
	// 	getDefaultMiddleware({
	// 		serializableCheck: false,
	// 	}),
});
