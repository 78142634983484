import s from './servicesSection.module.scss';

export const ServicesSection = ({ serviceClasses, serviceList = [] }) => {
	return (
		<section className={s.services}>
			<h3 className='sectionTitle'>Наши услуги</h3>

			<div className={s.services_serviceList}>
				{serviceList.map((service, i) => (
					<Service
						key={i}
						serviceClasses={serviceClasses}
						name={service.name}
						description={service.description}
					/>
				))}
			</div>
		</section>
	);
};

function Service({ serviceClasses, name, description }) {
	return (
		<div className={`${s.service} ${serviceClasses}`}>
			<h4>{name}</h4>

			<p>{description}</p>
		</div>
	);
}
