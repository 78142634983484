import { createAsyncThunk } from '@reduxjs/toolkit';
import { publishersApi } from '../../../core/api/api';
import { setPublisherList } from './publisherSlice';

export const getPublisherList = createAsyncThunk(
	'publishers/getPublisherList',
	async (_, { rejectWithValue, dispatch }) => {
		publishersApi
			.getPublisherList()
			.then((res) => {
				// console.log(res);
				dispatch(setPublisherList(res.data));
			})
			.catch((err) => console.log(err));
	}
);
